// SetupSystem.js
import React, { useState, useContext } from 'react';
import { StateSystemChange } from '../App';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
import { ScrollPanel } from 'primereact/scrollpanel';

const SetupSystem = ({move}) => {
    const { setSystemChange } = useContext(StateSystemChange);
    const token = localStorage.getItem('token');
    const [language, setLanguage] = useState(sessionStorage.getItem('language'));
    const [darkmode, setDarkmode] = useState(sessionStorage.getItem('darkmode'));
    const [sessionTheme, setSessionTheme] = useState(sessionStorage.getItem('AISTheme'));
    // const { changeTheme } = useContext(PrimeReactContext);

    const { t } = useTranslation();
    const lngs = {
        en: 'English',
        ja: '日本語',
        zhCn: '中国語'
    }
    const langeChange = (lng) => {
        sessionStorage.setItem('language', lng);
        setLanguage(lng);
        sendlanguage(lng);
        //App.js内に変更した事を投げる
        setSystemChange(true);
    }

    const sendlanguage = (language) => {
        const data = { token, language };
        const sendRequest = async (url) => {
            await fetch(url, {
                method: 'PUT', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
        };
        try {
            sendRequest(`${process.env.REACT_APP_API_SYSTEM}/setlanguage`);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const langContent = (
        <>
            <div
                style={{ 
                    display:'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px'
                    }}>
                {Object.keys(lngs).map((lng) => (
                    <Button
                        key={lng}
                        label = {lngs[lng]}
                        onClick={() => langeChange(lng)}
                        type='button'
                        className={language === lng ? 'p-button-primary' : 'p-button-secondary'}
                    />
                ))}
            </div>
        </>
    )

    const sendDark = (dark) => {
        const data = { token, dark };
        const sendRequest = async (url) => {
            await fetch(url, {
                method: 'PUT', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
        };
        try {
            sendRequest(`${process.env.REACT_APP_API_SYSTEM}/setdark`);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const sendTheme = (theme) => {
        const data = { token, theme };
        const sendRequest = async (url) => {
            await fetch(url, {
                method: 'PUT', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
        };
        try {
            sendRequest(`${process.env.REACT_APP_API_SYSTEM}/settheme`);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const darks = {
        dark: t('dark'),
        light: t('light')
    }
    const darkChange = (dark) => {
        sessionStorage.setItem('darkmode', dark);
        setDarkmode(dark);
        sendDark(dark);
        //App.js内に変更した事を投げる
        setSystemChange(true);
    }
    const themes = {
        laraAmber: 'Lara-amber',
        laraBlue: 'Lara-Blue',
        laraCyan: 'Lara-cyan',
        laraGreen: 'Lara-green',
        laraIndigo: 'Lara-indigo',
        laraPink: 'Lara-pink',
        laraPurple: 'Lara-purple',
        laraTeal: 'Lara-teal',
        bootstrap4Blue: 'Bootstrap4-Blue',
        bootstrap4Purple: 'Bootstrap4-Purple',
        mdDeeppurple: 'Md-Deeppurple',
        mdIndigo: 'Md-Indigo',
        mdcDeeppurple: 'Mdc-Deeppurple',
        mdcIndigo: 'Mdc-Indogo',
    }
    const themeChange = (theme) => {
        // console.log('setup.themeChange')
        sessionStorage.setItem('AISTheme', theme);
        setSessionTheme(theme);
        sendTheme(theme);
        //App.js内に変更した事を投げる
        setSystemChange(true);
    }

    const themeContent = (
        <>
            <div
                 style={{ 
                    display:'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px'
                    }}>
                {Object.keys(themes).map((theme) => (
                    <Button
                        key={theme}
                        label={themes[theme]}
                        onClick={() => themeChange(theme)}
                        type='button'
                        className={sessionTheme === theme ? 'p-button-primary' : 'p-button-secondary'}
                    />
                ))}
            </div>
        </>
    )
    const darkContent = (
        <>
            <div
                 style={{ 
                    display:'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px'
                    }}>
                {Object.keys(darks).map((dark) => (
                    <Button
                        key={dark}
                        label={darks[dark]}
                        onClick={() => darkChange(dark)}
                        type='button'
                        className={darkmode === dark ? 'p-button-primary' : 'p-button-secondary'}
                    />
                ))}
            </div>
        </>
    )

    const titleContent = (title)=>{
       return(
            <span style={{fontWeight:'bold'}}>{title}</span>
       )
    }


    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() => move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }
    // ConfigMonitoring()
    return (
        <div style={{ alignItems: 'self-start', justifyContent: 'center', height: '100%', textAlign: 'center' }}>
            <div
                style={{
                    height:'60px'
                }}>
                {/* タイトルセクション */}
                <Toolbar start={backButtonContent()} center={titleContent(t('systemSetting'))}
                    style={{ 
                        width: 'auto', height: '60px', 
                        paddingTop:'10px',
                        backgroundColor: 'var(--primary-color)', 
                        color:'var(--primary-color-text)' }}
                />
            </div>
            <ScrollPanel 
                style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'100px',
                                        backgroundColor: 'var(--primary-900)'}}>
                <div style={{ 
                    display:'flex', 
                    flexDirection:'column', 
                    textAlign:'left', 
                    gap:'20px',
                    padding:'20px'
                    }} >
                    <Fieldset
                        pt={{
                            legend:{
                                style:{  backgroundColor:'var(--primary-600)',color:'var(--primary-color-text)' }
                            }
                        }}
                        legend={t('lang')} 
                        style={{ backgroundColor:'var(--primary-700)' }}
                        >
                        <div className="m-0">
                            {langContent} 
                        </div>
                    </Fieldset>
                    <Fieldset 
                        pt={{
                            legend:{
                                style:{  backgroundColor:'var(--primary-600)',color:'var(--primary-color-text)' }
                            }
                        }}
                        legend={t('theme')} style={{ backgroundColor:'var(--primary-700)' }}>
                        <div className="m-0">
                            {themeContent} 
                        </div>
                    </Fieldset>
                    <Fieldset 
                        pt={{
                            legend:{
                                style:{  backgroundColor:'var(--primary-600)',color:'var(--primary-color-text)' }
                            }
                        }}
                        legend={t('darkMode')} style={{ backgroundColor:'var(--primary-700)' }}>
                        <div className="m-0">
                            {darkContent} 
                        </div>
                    </Fieldset>
                </div>
            </ScrollPanel>
        </div>
    );
};

export default SetupSystem;