// RegisterPage.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';


const RegisterPage = () => {
    const [ email, setEmail ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordconfirm, setPasswordconfirm ] = useState('');
    const [ termCheck, setTermCheck ] =  useState(false);
    const navigate = useNavigate();
    const apiRegisters = process.env.REACT_APP_API_USERS;
    const apiMailCheck = `${process.env.REACT_APP_API_USERS}/mailcheck`;
    const toast = useRef(null);
    const { t, i18n } = useTranslation();
    const lngs = {
        en: 'English',
        ja: '日本語',
        zhCn: '中国語'
    }
    const langeChange = (lng) => {
        i18n.changeLanguage(lng);
    }
    const navigateToLogin = () => {
        navigate('/login');
    };
    const navigateToThanks = () => {
        navigate('/thanks');
    };

    const sendMessage = async () => {
        const data = { email, password, username };
        // console.log(data);
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (url === apiMailCheck && response.status === 200) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + t('alreadyInUse'), life: 3000 });
                throw new Error('email is already registered');
            } else if (url === apiRegisters && response.status !== 200) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + t('couldNotRegister'), life: 3000 });
                throw new Error('Could not register');
            }
            // メールアドレスチェックでエラーがなければ、登録処理を実行
            if (url === apiMailCheck && response.status !== 200) {
                await sendRequest(apiRegisters);
            }
            if (url === apiRegisters && response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: t('anActivationEmailHasBeenSent'), life: 10000 });
                navigateToThanks(); // 登録後にログインページへ遷移
            }
        };
        try {
            await sendRequest(apiMailCheck);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            passwordconfirm: '',
            terms: false,
        },
        validate: (data) => {
            let errors = {};
            setUsername(data.username);
            setEmail(data.email);
            setPassword(data.password);
            setPasswordconfirm(data.passwordconfirm);
            setTermCheck(data.terms);
            if (!data.username) {
                errors.username = t('usernameIsRequired');
            }
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            if (!data.password) {
                errors.password = t('comfirmPasswordIsRequired');
            }
            if (data.username.length < 3) {
                errors.username = t('usernameIs6To15Characters');
            } else if (data.username.length > 15) {
                errors.username = t('usernameIs6To15Characters');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            if (data.password.length < 6) {
                errors.password = t('passwordIs6To20Characters');
            } else if (data.password.length > 20) {
                errors.password =t('passwordIs6To20Characters');
            }
            if (data.password !== data.passwordconfirm) {
                errors.password = t('passwordDoesNotMatch');
                errors.passwordconfirm = t('passwordDoesNotMatch');
            }
            if(!data.terms){
                errors.terms = t('pleaseAgree');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendMessage();
            formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const registCardTitle = ()=>{
        return (
                <div style={{justifyContent:'center', width:'100%',alignItems:'center',textAlign:'center'}}>
                <p>{process.env.REACT_APP_APPTITLE}</p> 
                <p><img src='logo512.png' alt='AI SEIJI LOGO' style={{width:'30%'}}/></p>
                {t('registerNow')}
                </div>
        );
    }
    return (
        <div className="Register-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2"
                style={{
                    width:'90%',
                    maxWidth:'450px'
                }}>
                <Card title={registCardTitle} 
                    style={{ 
                            width: '100%'
                        }}>
                    <div className="p-fluid">
                        <div className="p-fluid" style={{justifyContent:'center', width:'100%',display:'flex',textAlign:'center'}}>
                            {Object.keys(lngs).map((lng) => (
                                <Button
                                    key={lng}
                                    onClick={() => langeChange(lng)}
                                    type='button'
                                    className={i18n.resolvedLanguage === lng ? 'p-button-primary' : 'p-button-secondary' }
                                    style={{ height: '25px', width: '100px', margin:'2px 5px 20px 5px', textAlign: 'center'}}
                                >
                                    {lngs[lng]}
                                </Button>
                            ))}
                        </div>
                        <div className="p-fluid">
                            <InputText
                                inputid="username"
                                name="username"
                                value={username}
                                placeholder={t('userName')}
                                onChange={(e) => {
                                    formik.setFieldValue('username', e.target.value);
                                }}
                                autoComplete="username" />
                            {getFormErrorMessage('username')}
                        </div>
                        <div className="p-fluid">
                            <InputText
                                inputid="email"
                                name="email"
                                value={email}
                                placeholder={t('email')}
                                keyfilter="email"
                                onChange={(e) => {
                                    formik.setFieldValue('email', e.target.value);
                                }}
                                autoComplete="email" />
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="p-fluid">
                            <Password
                                inputid="password"
                                name="password"
                                value={password}
                                placeholder={t('password')}
                                onChange={(e) => {
                                    formik.setFieldValue('password', e.target.value);
                                }}
                                toggleMask
                                autoComplete="new-password" />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="p-fluid">
                            <Password
                                inputid="passwordconfirm"
                                name="passwordconfirm"
                                value={passwordconfirm}
                                placeholder={t('confirmPassword')}
                                onChange={(e) => {
                                    formik.setFieldValue('passwordconfirm', e.target.value);
                                }}
                                toggleMask
                                autoComplete="new-password" />
                            {getFormErrorMessage('passwordconfirm')}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                marginTop: '5px',
                                gap:'10px'
                            }}
                        >
                            <div style={{textAlign:'center'}}>
                                <a href={`/terms?lang=${i18n.language}`} target='_blank' rel="noreferrer">
                                    {t('term')}
                                </a>
                                 <span>・</span>
                                <a href={`/privacy?lang=${i18n.language}`} target='_blank' rel="noreferrer">
                                    {t('privacyPolicy')}
                                </a>
                            </div>
                            <div 
                                style={{
                                    width: '90%',
                                    textAlign:'center',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap:'5px',
                                    marginBottom: '10px'
                                }}>
                                <Checkbox 
                                    onChange={(e) => {
                                        formik.setFieldValue('terms', e.target.checked)
                                    }}
                                    checked={termCheck}
                                /> 
                                <div
                                    style={{
                                        textAlign:'left'
                                    }}
                                >
                                    {t('iHaveReadAndAcceptTheTermsAndPrivacyPolicy')}
                                   <div> {getFormErrorMessage('terms')}</div>
                                </div>
                            </div>
                        </div>
                        <Button label={t('signUp')} type="submit" />
                        <Button label={t('cancel')} type="button" className="p-button-secondary" onClick={navigateToLogin} style={{ marginTop: '10px' }} />
                    </div>
                </Card>
            </form>
        </div>
    );
};

export default RegisterPage;