import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';

const DashboardNews = ({onChange}) => {
    const { t } = useTranslation();

    const searchContent = (
        <div className="p-input-icon-left" 
        style={{
            width:'100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin:'5px',
            backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
            }}>
            <i className="pi pi-search" style={{color:'var(--primary-color-text)'}}/>
            <InputText placeholder={t('search')} 
                style={{width:'100%',
                    backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
                }}/>
        </div>
    )

    const chatSearchBar = () =>{
        return(
            <div className="card p-fluid" 
                style={{ 
                    width: '100%', 
                    height:'60px', 
                    margin:'0px', 
                    backgroundColor: 'var(--primary-500)',
                    color:'var(--primary-color-text)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>
                {searchContent}
            </div>
        )
    };


    // const sendMessage = async (e) => {
    //     // ここにバックエンドとの通信ロジックを追加
    //     const userMessage = { keyword:'仮想通貨'};
    //     setMessages(userMessage);

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_API_USER_OPENAIAPI}/news`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ message: 'キーワードは仮想通貨' }),
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         const data = await response.json();
    //         console.log(data);
    //         // setMessages(messages => [...messages, { text: data.reply, sender: 'gpt', user: selectedUser }]);
    //     } catch (error) {
    //         console.error("Failed to send the message: ", error);
    //     }
    //    // setInput(''); // Clear the input after sending
    // };

    // useEffect(() => {
    //     const cleanup = () => {
    //         // キャンセル処理
    //         // sendMessage();
    //     };
      
    //     return cleanup;
    //   }, []);

    return (
        <div
            style={{
                width: '100%', 
                height:'100%', 
            }}>
            { chatSearchBar() }
        </div>
    )
}
export default DashboardNews;