// Thanks.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

const Thanks = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate('/login');
    };

    return (
        <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center'  }}>
                <Card title={t('thankYouForYourRegistration')} style={{ width: 'auto' }}>
                    <div style={{ textAlign: 'center' }}>
                        <p>{t('sendToYourEmailPleaseActivation')}</p>

                        <p>{t('thankYou')}</p>
                        <Button label={t('login')} className="p-button-primary" onClick={navigateToLogin} style={{ marginTop: '10px' }} />
                    </div>
                </Card>
        </div>
    );
};

export default Thanks;