// LoginPage.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useRef(null);
    const { t, i18n } = useTranslation();
    const lngs = {
        en: 'English',
        ja: '日本語',
        zhCn: '中国語'
    }
    const langeChange = (lng) => {
        i18n.changeLanguage(lng);
    }
    // RegisterPageへ遷移するための関数を追加します
    const navigateToRegister = () => {
        formik.resetForm();
        navigate('/register');
    };

    const navigateToReactivation = () => {
        navigate('/reactivation');
    };


    const sendMessage = async () => {
        const data = { email, password };
        // console.log(data);
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            // console.log(response);
            if (response.status === 200) {
                const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                const expires = Date.now() + (10 * 24 * 60 * 60 * 1000); // 10日後のミリ秒
                localStorage.setItem('token', body.token, { expires });
                setTimeout(() => {
                    window.location.reload();
                },1000)
            } else if (response.status === 300) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'まだアクティベートできていません。', life: 3000 });
                navigateToReactivation();
            } else if (response.status === 405) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'パスワードが間違っています。', life: 3000 });
                throw new Error('Login failed');
            } else if (response.status === 404) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + 'が登録されていません。', life: 3000 });
                throw new Error('Login failed');
            } else {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + 'ログインできませんでした。', life: 3000 });
                throw new Error('Login failed');
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_USERS}/login`);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            if (!data.password) {
                errors.password = t('passwordIsRequired');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            if (data.password.length < 6) {
                errors.password = t('passwordIs6To20Characters');
            } else if (data.password.length > 20) {
                errors.password = t('passwordIs6To20Characters');
            }
            setEmail(data.email);
            setPassword(data.password);
            return errors;
        },
        onSubmit: (data) => {
            sendMessage();
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    const loginCardTitle = ()=>{
        return (
                <div style={{justifyContent:'center', width:'100%',alignItems:'center',textAlign:'center'}}>
                <p>{process.env.REACT_APP_APPTITLE}</p> 
                <p><img src='logo512.png' alt='AI SEIJI LOGO' style={{width:'30%'}}/></p>
                {t('signIn')}
                </div>
        );
    }
    return (
        <div className="login-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2"
                style={{
                    width:'90%',
                    maxWidth:'450px'
                }}>
                <Card title={loginCardTitle} style={{ width: '100%' }}>
                    <div className="p-fluid">
                        <div className="p-fluid" style={{justifyContent:'center', width:'100%',display:'flex',textAlign:'center'}}>
                            {Object.keys(lngs).map((lng) => (
                                <Button
                                    key={lng}
                                    onClick={() => langeChange(lng)}
                                    type='button'
                                    className={i18n.resolvedLanguage === lng ? 'p-button-primary' : 'p-button-secondary' }
                                    style={{ height: '25px', width: '100px', margin:'2px 5px 20px 5px', textAlign: 'center'}}
                                >
                                    {lngs[lng]}
                                </Button>
                            ))}
                        </div>
                        <div className="p-fluid">
                            <InputText
                                inputid="email"
                                name="email"
                                value={email}
                                placeholder={t('email')}
                                keyfilter="email"
                                onChange={(e) => {
                                    formik.setFieldValue('email', e.target.value);
                                }}
                                autoComplete="email" />
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="p-fluid">
                            <Password
                                inputid="password"
                                name="password"
                                value={password}
                                placeholder={t('password')}
                                onChange={(e) => {
                                    formik.setFieldValue('password', e.target.value);
                                }}
                                toggleMask
                                autoComplete="new-password" />
                                {getFormErrorMessage('password')}
                        </div>
                        {/* {localStorage.getItem('token')} */}
                        <Button label={t('signIn')} type="submit" />
                        <Button label={t('signUp')} type="button" className="p-button-secondary" onClick={navigateToRegister} style={{ marginTop: '10px' }} />
                    </div>
                </Card>
            </form>
        </div>
    );
};

export default LoginPage;