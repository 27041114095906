import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';

const Assistants = (assistantid) => {
    const move = assistantid.move;
    const { t } = useTranslation();
    const toast = useRef(null);
    const [ assistant, setAssistant ] = useState([]);
    const assistant_id = assistantid['selectedAssistant'];
    
    const getAssitant = async() =>{
        if(assistant_id){
            const sendRequest = async (url,data) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                });
                if (response.status === 200) {
                    const list = await response.json();
                    setAssistant(list[0]);
                    // console.log(list[0]);
                }
            };
            try {
                const data = { assistant_id };
                sendRequest(`${process.env.REACT_APP_API_ASSISTANTS}/assistant`,data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        getAssitant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assistant_id]);

    // useEffect(()=>{
    //     getAssitant();
    // },[])// このuseEffectはコンポーネントのマウント時にのみ実行されます

    const titleContent = ()=>{
        return(
         <>
         <span style={{marginLeft:'10px',fontWeight:'bold'}}>{t('assistant')}</span>
         </>
        )
     }

    const creatThred = async()=>{
        const token = localStorage.getItem('token');
        const name = `${assistant['name']} ${t('chat')}`
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'PUT', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                // const list = await response.json();
                toast.current.show({ severity: 'success', summary: t('success'), detail: t('addedChat'), life: 3000 });
                move(true);
            }else{
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorAddedChat'), life: 3000 });
            }
        };
        try {
            const threadType = 'openAI';
            const data = { token, assistant_id, name, threadType };
            sendRequest(process.env.REACT_APP_API_THREDS,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const chatToSetContent = ()=>{
        // if(assistant['share'] !==4){
            return(
                <Fieldset style={{textAlign:'left', margin:'20px 0px',backgroundColor: 'var(--primary-400)', color:'var(--primary-color-text)'}}>
                    <p className='m-0' style={{textAlign:'center'}}>
                        <Button 
                            style={{backgroundColor: 'var(--primary-600)', color:'var(--primary-color-text)'}}
                            label={t('addChat')} 
                            type='buttom' 
                            onClick={creatThred}/>
                    </p>
                </Fieldset>
            )
        // }else{
        //     return ;
        // }
    }
    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() => move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }

    const contents = () => {
        if(assistant['assistant_id']){
            return (
                <>
                    <div style={{ display: 'block', width:'100%',height:'100%'}}>
                        <Toast ref={toast} />
                        <Toolbar start={backButtonContent()} center={titleContent}
                            style={{ width: '100%', height: '60px', 
                            paddingTop:'10px',
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                        <ScrollPanel className="chatScroll" style={{ width: '100%', height: '100%', padding: '10px 10px 70px 10px', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div 
                                style={{ 
                                    height: '100%',width: '100%'
                                }}
                            >
                                <Card title={assistant['name']} style={{width:'100%',height:'100%', padding: '0px 20px', textAlign:'center',
                                        backgroundColor: 'var(--primary-300)', color:'var(--primary-color-text)'}}>
                                    <Image src={`uploads/${assistant['avatar']}`} alt={assistant['name']} width={'30%'} />
                                    {chatToSetContent()}
                                    <Fieldset
                                        pt={{
                                            legend:{
                                                style:{
                                                    backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)'
                                                }
                                            }
                                        }}
                                        legend={t('description')} 
                                        style={{
                                            textAlign:'left',
                                            backgroundColor: 'var(--primary-400)', color:'var(--primary-color-text)'}}>
                                        <p className='m-0' style={{textAlign:'center'}}>
                                            {assistant['description']}
                                        </p>
                                    </Fieldset>
                                </Card>
                            </div>
                        
                        </ScrollPanel>
                    </div>
                </>
            )
        }else{
            return (
                <>
                <div style={{ display: 'block', width:'100%'}}>
                    <Toolbar center={titleContent}
                        style={{ width: '100%', height: '60px',
                        paddingTop:'10px',
                        backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                    />
                    <Card title={ t('pleaseSelectAnAssistant') } 
                        style={{
                            textAlign:'center',
                            backgroundColor: 'var(--primary-400)', color:'var(--primary-color-text)'}} >
                    </Card>
                </div>
                </>
            )
        }
    }
    return contents()
}
export default Assistants