import React, { useState, useEffect, useContext } from 'react';
import { StateAssistantChange } from '../App';
import { useTranslation } from 'react-i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { PanelMenu } from 'primereact/panelmenu';
import { Avatar } from 'primereact/avatar';

const ChatListMenu = ({onChange}) => {
    const { t } = useTranslation();
    const { assistantChange, setAssistantChange } = useContext(StateAssistantChange);
    const [ expandedKeys, setExpandedKeys] = useState({'0':true,'1':true,'2':false});
    const [ assistantsSystemList, setAssistantsSystemList ] = useState([]);
    const [ assistantsList, setAssistantsList ] = useState([]);
    const [ friendsList, setFriendsList ] = useState([]);


   
    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])// このuseEffectはコンポーネントのマウント時にのみ実行されます

    const itemRenderer = (item) => (
        <div className='p-menuitem-content' style={{ backgroundColor:'var(--primary-500)'}}>
            <div className="flex align-items-center p-menuitem-link" onClick={()=>changeUser(item)} 
            style={{color:'var(--primary-color-text)'}}>
                {item.avatar ? (
                    <Avatar image={item.avatar} size="normal" shape="circle"  style={{minWidth:'45px',minHeight:'45px'}}/>
                ):(
                    <Avatar icon="pi pi-user" size="normal" shape="circle"  style={{minWidth:'45px',minHeight:'45px'}}/>
                )}
                <div style={{marginLeft:'10px'}}>{item.name}</div>
                {item.badge && <Badge className="ml-auto" value={item.badge} />}
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </div>
        </div>
    );

    // 仮ユーザーデーター
    const items = [
        {
            key: '0',
            label: t('shareSystem'),
            items: assistantsSystemList.map((threadsList, index) => ({
                key: `0_${index+1}`,
                name: threadsList.name,
                assistant_id: threadsList.assistant_id,
                thread_id: threadsList.thread_id,
                avatar: `uploads/${threadsList.image}`,
                template: itemRenderer,
                role:'system'
            })),
        },
        {
            key: '1',
            label: t('assistantChat'),
            items: assistantsList.map((threadsList, index) => ({
                key: `1_${index+1}`,
                name: threadsList.name,
                assistant_id: threadsList.assistant_id,
                thread_id: threadsList.thread_id,
                avatar: `uploads/${threadsList.image}`,
                template: itemRenderer,
                role:'openAI'
            })),
        },
        {
            key: '2',
            label: t('friendsChat'),
            items: friendsList.map((threadsList, index) => ({
                key: `1_${index+1}`,
                name: threadsList.name,
                assistant_id: threadsList.assistant_id,
                thread_id: threadsList.thread_id,
                avatar: `uploads/${threadsList.image}`,
                template: itemRenderer,
                role:'friend'
            })),
        }
    ]
    const searchContent = (
        <div className="p-input-icon-left" 
        style={{
            width:'100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin:'5px',
            backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
            }}>
            <i className="pi pi-search" style={{color:'var(--primary-color-text)'}}/>
            <InputText placeholder={t('search')} 
                style={{width:'100%',
                    backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
                }}/>
        </div>
    )

    const chatSearchBar = () =>{
        return(
            <div className="card p-fluid" 
            style={{ 
                width: 'auto', 
                height:'60px', 
                margin:'0px', 
                backgroundColor: 'var(--primary-500)',
                color:'var(--primary-color-text)',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                {searchContent}
            </div>
        )
    };

    const changeUser = (thread) =>{
        if(thread){
            onChange(thread)
        }
    }


    const menuItemList = () => {
        return(
            <div style={{ height: '100%' }}>
                <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'60px' }}>
                    <PanelMenu
                            pt={{
                                menuContent:{
                                    style:{
                                        backgroundColor:'var(--primary-500)',color:'var(--primary-color-text)'
                                    }},
                                headerAction: () => ({
                                     style:{
                                        backgroundColor:'var(--primary-500)',
                                        color:'var(--primary-color-text)'
                                     }
                                    })
                            }}
                            model={items} className="w-full md:w-15rem"
                            expandedKeys={expandedKeys} 
                            onExpandedKeysChange={setExpandedKeys}
                            style={{
                                    width:'100%',padding:'5px'
                                }}
                            />
                </ScrollPanel>
            </div>
        )
    }

    const getAssitantList = async() =>{
        const token = localStorage.getItem('token');
        let threadType;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const list = await response.json();
                switch(data.threadType){
                    case 'system': setAssistantsSystemList(list);
                    break;
                    case 'openAI': setAssistantsList(list);
                    break;
                    case 'friends': setFriendsList(list);
                    break;
                    default:;
                }
                }
            };
        try {
            const url = `${process.env.REACT_APP_API_THREDS}/assistantlist`;
            threadType = 'system';
            let data = { token, threadType };
            await sendRequest(url,data);
            threadType = 'openAI';
            data = { token, threadType };
            await sendRequest(url,data);
            threadType = 'friends';
            data = { token, threadType };
            await sendRequest(url,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }


    useEffect(() => {
        if(assistantChange){
            getAssitantList();
        }
        setAssistantChange(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assistantChange]);

    useEffect(()=>{
        getAssitantList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])// このuseEffectはコンポーネントのマウント時にのみ実行されます



return (
    <div 
        style={{
            backgroundColor:'var(--primary-400)',
            width: '100%', 
            height:'100%', 
        }}
    >
        {chatSearchBar()}
        {menuItemList()}
    </div>
)
}
export default ChatListMenu;