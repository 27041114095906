// SetupBoard.js
import React, { useEffect, useState } from 'react';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import SetupListMenu from './SetupListMenu';
import SetupLogout from './SetupLogout';
import SetupSystem from './SetupSystem';
import SetupUser from './SetupUser';
import TermsAndPoricy from './TermsAndPoricy';

const SetupBoard = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [elementHeight, setElementHeight] = useState(0);
  const [ centerPanelSm, setCenterPanelSm ] = useState();
  const [ rightPanelSm, setRightPanelSm ] = useState();
  const [ centerPanel, setCenterPanel ] = useState();
  const [ rightPanel, setRightPanel ] = useState();

  const selectMenuChange = (selectMenu) => {
    setSelectedMenu(selectMenu);
  }
  const backPanelMove = (back) =>{
    if(back){
      setSelectedMenu(null);
      if(window.innerWidth < 768){
        //スマホ画面
        if(rightPanelSm)rightPanelSm.style.display = 'none';
        if(centerPanelSm)centerPanelSm.style.display = 'block';
    }
    }
  }
  // changeCenterPanel();
  useEffect(() => {
    const calculateElementHeight = () => {
      // ビューポートの高さを取得
      const viewportHeight = window.innerHeight;
      // 利用可能な高さを計算
      const availableHeight = viewportHeight;
      // 要素に計算された高さを適用
      setElementHeight(availableHeight);
      setCenterPanelSm(document.querySelector('.center-panel-sm'));
      setCenterPanel(document.querySelector('.center-panel'));
      setRightPanelSm(document.querySelector('.right-panel-sm'));
      setRightPanel(document.querySelector('.right-panel'));
    };
    // コンポーネントがマウントされた時に高さを計算
    calculateElementHeight();
    // ウィンドウのリサイズ時に再計算
    window.addEventListener('resize', calculateElementHeight);
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', calculateElementHeight);
      document.body.style.height = calculateElementHeight;
    }
  }, []);

  let rightContent;
  const moveRightWindow =() =>{
    if(window.innerWidth < 768){
      //スマホ画面
      if(rightPanelSm)rightPanelSm.style.display = 'block';
      if(centerPanelSm)centerPanelSm.style.display = 'none';
    }else{
      //PC Tablet画面
      if(rightPanel)rightPanel.style.display = 'block';
      if(centerPanelSm)centerPanelSm.style.display = 'block';
      if(centerPanel)centerPanel.style.display = 'block';
    }
  }
  switch(selectedMenu){
    case 'logout':
      rightContent = < SetupLogout move={backPanelMove}/>;
      moveRightWindow();
      break;
    case 'user':
      rightContent = < SetupUser move={backPanelMove}/>;
      moveRightWindow();
      break;
    case 'system':
      rightContent =< SetupSystem move={backPanelMove}/>;
      moveRightWindow();
      break;
    case 'privacyPolicy':
      rightContent =< TermsAndPoricy purpose='privacy' patern='system' move={backPanelMove}/>;
      moveRightWindow();
      break;
    case 'term':
      rightContent =< TermsAndPoricy purpose='terms' patern='system' move={backPanelMove}/>;
      moveRightWindow();
      break;
    default:
      rightContent = <div></div>;
      break;
  }
  if(window.innerWidth < 768){
    //スマホ画面
    return (
      <>
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          className="center-panel-sm" 
          style={{ 
            width:'100%',
            height: `${elementHeight-50}px`,
            backgroundColor:'var(--primary-400)'
          }} 
        >
          {/* 中セクション（可変） */}
          <SetupListMenu onChange={selectMenuChange} />
        </SplitterPanel>
        <SplitterPanel 
          className="right-panel-sm" 
          style={{ 
            display:'none',
            width:'100%',
            height: `${elementHeight-50}px`,
            backgroundColor:'var(--primary-900)'
          }}>
          {/* 右セクション（残りのスペース） */}
          {rightContent}
        </SplitterPanel>
      </Splitter>
      </>
    );
  }else{
    //PC tablet画面
    return (
      <>
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          size={20}
          className="center-panel" 
          style={{ 
            height: `${elementHeight}px`,
            backgroundColor:'var(--primary-400)'}}>
          {/* 中セクション（可変） */}
          <SetupListMenu onChange={selectMenuChange} />
        </SplitterPanel>
        <SplitterPanel className="right-panel" style={{ height: `${elementHeight}px`, backgroundColor:'var(--primary-900)'}}>
          {/* 右セクション（残りのスペース） */}
          {rightContent}
        </SplitterPanel>
      </Splitter>
      </>
    );
  }
};

export default SetupBoard;