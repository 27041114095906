// Activation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

const Activation = () => {
  const [ activationFlug, setActivationFlug ] = useState(0);
  const navigate = useNavigate();
  const navigateToLogin = () => {
      navigate('/login');
  };
  const navigateToResendActivation = () => {
      navigate('/reactivation');
  };
  

  const activationDone = function (){
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const email = urlParams.get('email');
    const data = { email, code };
    const sendRequest = async (url) => {
      const response = await fetch(url, {
          method: 'POST', // HTTPメソッド
          headers: {
              'Content-Type': 'application/json', // コンテントタイプをJSONに指定
          },
          body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
      });

      if(response.status === 200) setActivationFlug(1)
      // return fixActivationCode();
      if (response.status !== 200) {
          setActivationFlug(2)
          //  notfixActivationCode();
          throw new Error('Could not activate');
      }
    };
    try {
        sendRequest(`${process.env.REACT_APP_API_USERS}/activation`); 
    } catch (error) {
      setActivationFlug(2)
      console.error('Error:', error);
      // return ;
    }
  };

  const fixActivationCode = () => {
      const content = (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
          <Card title="TUNDERE AI" style={{ width: 'auto' }}>
            <div style={{ textAlign: 'center' }}>
              <p>アクティベーションされました。</p>
              <p>ありがとうございました。</p>
              <Button label="ログイン" className="p-button-primary" onClick={navigateToLogin} style={{ marginTop: '10px' }} />
            </div>
          </Card>
        </div>
      );
      return content;
    };
  
  const notfixActivationCode = () => {
    const content = (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
        <Card title="TUNDERE AI" style={{ width: 'auto' }}>
          <div style={{ textAlign: 'center' }}>
            <p>アクティベーションエラー。</p>
            <p>少し時間をおいてから、リンクを正しく開いてください。</p>
            <p>アクティベーションを再度行いたい方は、下のボタンから行ってください。</p>
            <p><Button label="アクティベーション再送" className="p-button-primary" onClick={navigateToResendActivation} style={{ marginTop: '10px' }} /></p>
            <Button label="ログイン" className="p-button-secondary" onClick={navigateToLogin} style={{ marginTop: '10px' }} />
          </div>
        </Card>
      </div>
    );
    return content;
  };
  activationDone();

  let cardContent = null;
  if(activationFlug === 1){
      cardContent = fixActivationCode();
  }else if(activationFlug === 2){
      cardContent = notfixActivationCode();
  }

  return (
      <div>
        {cardContent}
      </div>
  )

};

export default Activation;