import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

const TermsAndPoricy =({purpose,patern,move}) =>{
    const termJP = [
        {
            chapter:`第1条（目的）`,
            content:`本規約は、SEIJI LABO（以下「当社」といいます。）が提供するAIアプリ（以下「本アプリ」といいます。）の利用条件を定めることを目的とします。`
        },
        {
            chapter:`第2条（適用範囲）`,
            content:`本規約は、本アプリを利用するすべてのユーザーに適用されます。`
        },
        {
            chapter:`第3条（利用許諾）`,
            content:`当社は、ユーザーに本アプリの利用許諾を付与します。
                    ユーザーは、本規約に従って、本アプリを利用するものとします。`
        },
        {
            chapter:`第4条（利用方法）`,
            content:`ユーザーは、本アプリを私的な利用のみに使用することができます。
            ユーザーは、本アプリを商用目的で使用することはできません。
            ユーザーは、本アプリを違法または不正な目的で使用することはできません。`
        },
        {
            chapter:`第5条（禁止事項）`,
            content:`ユーザーは、以下の行為をしてはいけません。
            * 本アプリを複製、改変、翻訳、逆コンパイル、または逆アセンブルすること
            * 本アプリを第三者に譲渡、貸与、または使用許諾すること
            * 本アプリをネットワーク上で公開すること
            * 本アプリをサーバー等にインストールすること
            * 本アプリを自動化ツール等で利用すること
            * 本アプリを違法または不正な目的で使用すること`
        },
        {
            chapter:`第6条（責任）`,
            content:`当社は、本アプリの利用によって生じた損害について、一切責任を負いません。
            ユーザーは、本アプリの利用によって生じた損害について、自己の責任と費用で解決するものとします。`
        },
        {
            chapter:`第7条（変更）`,
            content:`社は、本規約を変更することがあります。
            当社は、本規約を変更した場合は、本アプリ内に変更内容を掲載するものとします。
            ユーザーは、本規約の変更に同意するものとします。`
        },
        {
            chapter:`第8条（準拠法）`,
            content:`本規約は、日本国の法律に準拠するものとします。`
        },
        {
            chapter:`第9条（管轄裁判所）`,
            content:`本規約に関する紛争は、東京地方裁判所を第一審の専属管轄裁判所とします。`
        },
        {
            chapter:`第10条（施行日）`,
            content:`本規約は、2024年4月4日に施行します。`
        }
     ];
    const termEN = [
        {
          chapter: `Article 1 (Purpose)`,
          content: `These Terms of Use govern the use of the AI application (hereinafter referred to as the "App") provided by SEIJI LABO (hereinafter referred to as the "Company").`
        },
        {
          chapter: `Article 2 (Scope)`,
          content: `These Terms of Use apply to all users who use the App.`
        },
        {
          chapter: `Article 3 (License Grant)`,
          content: `The Company grants the user a license to use the App.
           The user shall use the App in accordance with these Terms of Use.`
        },
        {
          chapter: `Article 4 (Method of Use)`,
          content: `The user may use the App for private use only. 
          The user may not use the App for commercial purposes. 
          The user may not use the App for illegal or unauthorized purposes.`
        },
        {
          chapter: `Article 5 (Prohibited Acts)`,
          content: `The user shall not perform the following acts: 
          * Reproduce, modify, translate, reverse compile, or reverse assemble the App. 
          * Transfer, lend, or grant a license to use the App to a third party. 
          * Disclose the App on a network. 
          * Install the App on a server or other device. 
          * Use the App with an automation tool or other similar tool. 
          * Use this app for illegal or unauthorized purposes.`
        },
        {
          chapter: `Article 6 (Liability)`,
          content: `The Company shall not be liable for any damages caused by the use of the App.
           The user shall be responsible for resolving any damages caused by the use of the App at his or her own expense.`
        },
        {
          chapter: `Article 7 (Changes)`,
          content: `The Company may change these Terms of Use. 
          The Company shall post the contents of the changes to the App when it changes these Terms of Use. The user agrees to any changes to these Terms of Use.`
        },
        {
          chapter: `Article 8 (Governing Law)`,
          content: `These Terms of Use shall be governed by and construed in accordance with the laws of Japan.`
        },
        {
          chapter: `Article 9 (Jurisdiction)`,
          content: `The Tokyo District Court shall have exclusive jurisdiction over any disputes arising out of or relating to these Terms of Use.`
        },
        {
          chapter: `Article 10 (Effective Date)`,
          content: `These Terms of Use shall be effective as of April 4, 2024.`
        }
      ];

    const termCH = [
        {
            chapter: `第1条（目的）`,
            content: `本协议旨在规定SEIJI LABO（以下简称“公司”）提供的AI应用程序（以下简称“本应用程序”）的使用条件。`
        },
        {
            chapter: `第2条（适用范围）`,
            content: `本协议适用于所有使用本应用程序的用户。`
        },
        {
            chapter: `第3条（许可授予）`,
            content: `公司向用户授予使用本应用程序的许可。用户应根据本协议使用本应用程序。`
        },
        {
            chapter: `第4条（使用方式）`,
            content: `用户仅可将本应用程序用于私人用途。用户不得将本应用程序用于商业目的。用户不得将本应用程序用于非法或不正当目的。`
        },
        {
            chapter: `第5条（禁止事項）`,
            content: `用户不得进行以下行为：
            * 复制、修改、翻译、逆编译或反汇编本应用程序。
            * 将本应用程序转让、出租或使用许可给第三方。
            * 在网络上公开本应用程序。
            * 将本应用程序安装在服务器或其他设备上。
            * 使用自动化工具等工具使用本应用程序。
            * 将本应用程序用于非法或不正当目的。`
        },
        {
            chapter: `第6条（责任）`,
            content: `公司对因使用本应用程序而造成的任何损害不承担任何责任。
            用户应自行承担责任和费用解决因使用本应用程序而造成的任何损害。`
        },
        {
            chapter: `第7条（变更）`,
            content: `公司可以变更本协议。
            公司在变更本协议时，将在本应用程序内发布变更内容。
            用户同意任何对本协议的变更。`
        },
        {
            chapter: `第8条（準拠法）`,
            content: `本协议应受日本国法律管辖并按其解释。`
        },
        {
            chapter: `第9条（管轄裁判所）`,
            content: `对于因本协议引起的或与本协议有关的任何争议，东京地方法院应为第一审专属管辖法院。`
        },
        {
            chapter: `第10条（施行日）`,
            content: `本协议于2024年4月4日生效。`
        }
     ];

    const privacyJP = [
       {
            chapter: ` 1. はじめに`,
            content: `SEIJI LABO（以下「当社」といいます。）は、ユーザーのプライバシーを尊重し、ユーザーの個人情報の保護に努めています。
            本プライバシーポリシーは、当社が提供するAIアプリ（以下「本アプリ」といいます。）における個人情報の取り扱いについて定めたものです。`
       },
       {
            chapter: `2. 適用範囲`,
            content: `本プライバシーポリシーは、本アプリを利用するすべてのユーザーに適用されます。`
       },
       {
            chapter: `3. 個人情報の取得`,
            content: `当社は、本アプリを通じて以下の個人情報を取得します。
            * ユーザーの氏名、住所、電話番号、メールアドレスなどの連絡先情報
            * ユーザーの生年月日、性別などの属性情報
            * ユーザーの利用履歴
            * ユーザーの端末情報
            * その他、本アプリの利用に関連する情報`
       },
       {
            chapter: `4. 個人情報の利用目的`,
            content: `当社は、取得した個人情報を以下の目的で利用します。

            * 本アプリの提供
            * ユーザーへのサポート
            * ユーザーへのマーケティング活動
            * サービスの改善
            * 統計分析
            * その他、上記に関連する目的`
       },
       {
            chapter: `5. 個人情報の第三者提供`,
            content: `当社は、以下の場合を除いて、ユーザーの同意を得ることなく個人情報を第三者に提供しません。

            * 法令に基づく開示
            * 人の生命、身体または財産を保護するために必要な場合
            * 公共の利益のために必要な場合
            * 当社の業務委託先に対して、業務遂行のために必要な範囲内において開示する場合`
       },
       {
            chapter: `6. 個人情報の管理`,
            content: `当社は、個人情報を適切に管理し、漏洩、滅失、毀損の防止に努めます。`
       },
       {
            chapter: `7. ユーザーの権利`,
            content: `ユーザーは、当社に対して、以下の権利を有します。

            *  個人情報の開示、訂正、削除、利用停止等を請求する権利
            * 第三者提供の停止を請求する権利
            * 利用目的の通知を受ける権利`
       },
       {
            chapter: `8. プライバシーポリシーの変更`,
            content: `当社は、必要に応じて本プライバシーポリシーを変更することがあります。
            変更後のプライバシーポリシーは、本アプリ内に掲載します。`
       },
       {
            chapter: `9. お問い合わせ`,
            content: `本プライバシーポリシーに関するお問い合わせは、以下のE-mailまでご連絡ください。`
       },
       {
            chapter: `SEIJI LABO`,
            content: `info@seiji-lab.com`
       },
       {
            chapter: `10. 施行日`,
            content: `本プライバシーポリシーは、2024年4月4日より施行します。`
       }
        ];
    const privacyEN = [
        {
          chapter: `1. Introduction`,
          content: `SEIJI LABO (hereinafter referred to as "the Company") respects the privacy of its users and strives to protect their personal information. 
          This Privacy Policy sets forth the Company's policy for handling personal information collected through the AI application (hereinafter referred to as "the App") provided by the Company.`
        },
        {
          chapter: `2. Scope`,
          content: `This Privacy Policy applies to all users who use the App.`
        },
        {
          chapter: `3. Acquisition of Personal Information`,
          content: `The Company collects the following personal information through the App:
        
            * Contact information such as the user's name, address, phone number, and email address
            * Attribute information such as the user's date of birth and gender
            * User usage history
            * User terminal information
            * Other information related to the use of the App`
        },
        {
          chapter: `4. Purpose of Use of Personal Information`,
          content: `The Company uses the collected personal information for the following purposes:
            
            * To provide the App
            * To provide support to users
            * To conduct marketing activities to users
            * To improve the service
            * To conduct statistical analysis
            * Other purposes related to the above`
        },
        {
          chapter: `5. Provision of Personal Information to Third Parties`,
          content: `The Company will not provide personal information to third parties without the user's consent, except in the following cases:
            
            * Disclosure based on laws and regulations
            * When necessary to protect the life, body, or property of a person
            * When necessary for the public interest
            * When disclosing to a subcontractor of our company within the scope necessary for the execution of business.`
        },
        {
          chapter: `6. Management of Personal Information`,
          content: `The Company will appropriately manage personal information and strive to prevent leaks, loss, and damage.`
        },
        {
          chapter: `7. User Rights`,
          content: `Users have the following rights against the Company:
            
            * The right to request disclosure, correction, deletion, and suspension of use of personal information
            * The right to request the suspension of provision to third parties
            * The right to be notified of the purpose of use`
        },
        {
          chapter: `8. Changes to the Privacy Policy`,
          content: `The Company may change this Privacy Policy as necessary. 
          The changed Privacy Policy will be posted in the App.`
        },
        {
          chapter: `9. Inquiries`,
          content: `For inquiries regarding this Privacy Policy, please contact the following E-mail`
        },
        {
          chapter: `SEIJI LABO`,
          content: `info@seiji-lab.com`
        },
        {
          chapter: `10. Effective Date`,
          content: `This Privacy Policy will be effective as of April 4, 2024.`
        }
      ];

    const privacyCH = [
        {
          chapter: `1. 开始`,
          content: `SEIJI LABO（以下简称“公司”）尊重用户隐私并努力保护用户个人信息。本隐私政策规定了公司在其提供的AI应用程序（以下简称“本应用程序”）中处理个人信息的方式。`
        },
        {
          chapter: `2. 适用范围`,
          content: `本隐私政策适用于所有使用本应用程序的用户。`
        },
        {
          chapter: `3. 个人信息的获取`,
          content: `公司通过本应用程序收集以下个人信息：
          * 用户的姓名、地址、电话号码、电子邮件地址等联系方式
          * 用户的出生日期、性别等属性信息
          * 用户的使用历史记录
          * 用户的终端信息
          * 其他与本应用程序使用相关的信息`
        },
        {
          chapter: `4. 个人信息的使用目的`,
          content: `公司使用收集的个人信息用于以下目的：
          * 提供本应用程序
          * 向用户提供支持
          * 向用户进行营销活动
          * 改善服务
          * 进行统计分析
          * 其他与上述目的相关目的`
        },
        {
          chapter: `5. 个人信息的第三者提供`,
          content: `除以下情况外，公司不会在未经用户同意的情况下向第三方提供个人信息：
          * 根据法律法规进行披露
          * 为保护人的生命、身体或财产安全所必需
          * 为公共利益所必需
          * 向公司的委托方在履行业务职责的必要范围内进行披露`
        },
        {
          chapter: `6. 个人信息的管理`,
          content: `公司将妥善管理个人信息，并努力防止泄漏、灭失和损坏。`
        },
        {
          chapter: `7. 用户的权利`,
          content: `用户对公司拥有以下权利：
          * 请求公司披露、更正、删除、停止使用等个人信息
          * 请求停止向第三方提供
          * 收到使用目的通知`
        },
        {
          chapter: `8. 隐私政策变更`,
          content: `公司可以根据需要变更本隐私政策。变更后的隐私政策将发布在本应用程序中。`
        },
        {
          chapter: `9. 联系方式`,
          content: `对于本隐私政策的任何疑问，请联系以下窗口：`
        },
        {
          chapter: `SEIJI LABO`,
          content: `info@seiji-lab.com`
        },
        {
          chapter: `10. 生效日期`,
          content: `本隐私政策于2024年4月4日生效。`
        }
      ];

    const [ elementHeight, setElementHeight ] = useState(0);
    useEffect(() => {
        const calculateElementHeight = () => {
          // ビューポートの高さを取得　大きい
          const viewportHeight = window.innerHeight;
          // 利用可能な高さを計算　
          const availableHeight = viewportHeight;
          // 要素に計算された高さを適用
          setElementHeight(availableHeight);
        };
        // コンポーネントがマウントされた時に高さを計算
        calculateElementHeight();
        // ウィンドウのリサイズ時に再計算
        window.addEventListener('resize', calculateElementHeight);
        // クリーンアップ関数
        return () => {
          window.removeEventListener('resize', calculateElementHeight);
          document.body.style.height = calculateElementHeight;
        }
      }, []);
    const { i18n } = useTranslation();
    let Content;
    let Title;
    const [params, setParams] = useState({});
    const UseUrlParams = () => {
        useEffect(() => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          setParams(Object.fromEntries(urlSearchParams));
        }, []);
        return params;
    }
    const selectLanguage =()=>{
            const { lang } = UseUrlParams();
            if(!lang)setParams({'lang':i18n.language});
            return lang;
    }
    const getSelectLang = selectLanguage();

    const backButtonContent =() =>{
      if(window.innerWidth < 768){
          return(
              <Button
                  icon={'pi pi-angle-double-left'}
                  onClick={() => move(true)}
                  type='button'
                  className='p-button-secondary'
                  style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '20%',
                      backgroundColor: 'var(--primary-800)',
                      color: 'var(--primary-color-text)',
                      borderColor:'var(--primary-color-text)',
                  }}
              />
          )
      }else{
          return '';
      }
    }

    const titleContent = (title)=>{
      return(
           <span style={{fontWeight:'bold'}}>{title}</span>
      )
   }
    const createContent = (data) =>{
        const createData = data.map((item,index) =>{
            return(
                <li key={index}
                  style={
                    {
                        listStyleType: 'none'
                    }
                  }
                >
                    <div>
                        <div
                            style={{
                                fontWeight:'bold',
                            }}
                        >
                            {item.chapter}
                        </div>
                        <div
                            style={{
                                paddingLeft:'10px'
                            }}
                        >
                            {item.content.split('\n').map((line, index) => (
                                <div key={index}>
                                    {line}
                                    {index < item.content.length - 1 && <br />}
                                </div>
                            ))}
                        </div>

                    </div>
                </li>
            )
        })
        return createData;
    }

    if(purpose === 'privacy'){
        //プライバシーポリシー
        Content = 'Privacy Policy';
        if(getSelectLang==='ja'){
            Title='プライバシーポリシー';
            Content = createContent(privacyJP);
        }
        else if(getSelectLang==='zhCn'){
            Title='私隐政策';
            Content = createContent(privacyCH);
        }
        else{
            Title='Privacy Policy';
            Content = createContent(privacyEN);
        }

    }else if(purpose === 'terms'){
        //規約事項
        if(getSelectLang==='ja'){
            Title='利用規約';
            Content = createContent(termJP);
        }
        else if(getSelectLang==='zhCn'){
            Title='服务条款';
            Content = createContent(termCH);
        }
        else{
            Title='Terms of service';
            Content = createContent(termEN);
        }
    }
    if(patern === 'none'){
        return(
            <div className="Register-container" 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: elementHeight,
                    width: '100%',
                }}
            >
                <Card title={Title}
                    pt={{
                        body: {
                            className: 'bg-primary border-round-lg' ,
                        },
                        root:{
                            style:{
                                height:elementHeight*0.9,
                                width:'90%'
                            }
                        }
                    }}
                >
                    <ScrollPanel
                        style={{
                            width: '100%',
                            height: elementHeight*0.9-100,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap:'20px'
                            }}
                        >
                            {Content}
                        </div>
                    </ScrollPanel>
                </Card>
            </div>
        ) 
    }else if(patern === 'system'){
        return(
          <div style={{ alignItems: 'self-start', justifyContent: 'center', height: '100%', textAlign: 'center' }}>
              <div
                  style={{
                      height:'60px'
                  }}>
                  {/* タイトルセクション */}
                  <Toolbar start={backButtonContent()} center={titleContent(Title)}
                      style={{ 
                          width: 'auto', height: '60px', 
                          paddingTop:'10px',
                          backgroundColor: 'var(--primary-color)', 
                          color:'var(--primary-color-text)' }}
                  />
              </div>
              <div className="Register-container" 
                  style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      height: elementHeight-60,
                      width: '100%',
                  }}
              >
                  <Card 
                      pt={{
                          body: {
                              className: 'bg-primary border-round-lg' ,
                          },
                          root:{
                              style:{
                                  height:(elementHeight-60)*0.9,
                                  width:'90%'
                              }
                          }
                      }}
                  >
                      <ScrollPanel
                          style={{
                              width: '100%',
                              height: (elementHeight-60)*0.8,
                          }}
                      >
                          <div
                              style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  textAlign:'left',
                                  gap:'20px'
                              }}
                          >
                              {Content}
                          </div>
                      </ScrollPanel>
                  </Card>
              </div>
            </div>
        ) 
    }

}
export default TermsAndPoricy