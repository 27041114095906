import React, { useRef, useState, useEffect, useContext } from 'react';
import { StateAssistantChange } from '../App';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputSwitch } from 'primereact/inputswitch';
import { Image } from 'primereact/image';
import { useFormik } from 'formik';

const AssistantEditor = ( selectedAssistant ) => {
    const token = localStorage.getItem('token');
    const move = selectedAssistant.move;
    // console.log(selectedAssistant);
    const { t } = useTranslation();
    const toast = useRef(null);
    const { setAssistantChange } = useContext(StateAssistantChange);
    const [count, setCount] = useState(0);

    const [ name , setName ] = useState('');
    const [ instructions , setInstructions ] = useState('');
    const [ functions , setFunctions ] = useState('');
    const [ description , setDescription ] = useState('');
    const [ file_ids , setFile_ids ] = useState('');
    const [ metadata , setMetadata ] = useState('');
    const [ avatar , setAvatar ] = useState('');
    const [ avatarEdit , setAvatarEdit ] = useState('');
    const [ shareSelect , setShareSelect ] = useState('');
    const [ share , setShare ] = useState('');
    const [ modelSelect , setModelSelect ] = useState('');
    const [ model , setModel ] = useState('');
    const [ voiceModelSelect, setVoiceModelSelect ] = useState('');
    const [ voiceModel, setVoiceModel ] = useState('');
    const [ voiceTestText, setVoiceTestText ] = useState('');
    const [ voiceTestFile, setVoiceTestFile ] = useState('');
    const voiceTestRef = useRef(null);

    const [ models , setModels ] = useState({reply:[]});
    const [ modelsLists , setModelsLists ] = useState([]);
    const [ sharesLists , setSharesLists ] = useState([]);
    const [ codeinterpreter, setCodeinterpreter ] = useState(false);
    const [ retrieval, setRetrieval ] = useState(false);
    const [ imageGenerate, setImageGenerate ] = useState(false);
    const [ transcription, setTranscription ] = useState(false);
    const imageGenerateInstruction = 'When there is a callback for createImage in tools functions, always include the image link in the message.';
    const imageGenerateFunction = {
        type:'function',
        function:{
            "name": "createImage",
            "description": "Image generation instructions",
            "parameters": {
                "type": "object",
                "properties": {
                    "prompt": {
                        "type": "string",
                        "description": "Request to generate images"
                    },
                    "size": {
                        "type": "string",
                        "enum": [
                            "256x256",
                            "512x512",
                            "1024x1024",
                            "1792x1024",
                            "1024x1792"
                        ]
                    }
                },
                "required": [
                    "prompt",
                    "size"
                ]
            }
        }
    };
    const transcriptionInstruction = 'When there is a callback for createTranscription in tools functions, always include text messages.';
    const transcriptionFunction = {
        type:'function',
        function:{
            "name": "createTranscription",
            "description": "Transcribes audio into the input language.",
            "parameters": {
                "type": "object",
                "properties": {
                    "file": {
                        "type": "string",
                        "description": "Request to The audio file , in one of these formats: flac, mp3, mp4, mpeg, mpga, m4a, ogg, wav, or webm."
                    },
                    "prompt": {
                        "type": "string",
                        "description": "Request to prompt. An optional text to guide the model's style or continue a previous audio segment. The prompt should match the audio language."
                    },
                    "language": {
                        "type": "string",
                        "description": "The language of the input audio. Supplying the input language in ISO-639-1 format will improve accuracy and latency."
                    }
                },
                "required": [
                    "file",
                    "language"
                ]
            }
        }
    };
    //function tool機能追加
    const functionToolArray = [
        {
            title:t('codeInterpreter'),
            tooltip:t('codeInterpreterTooltip'),
            checked:codeinterpreter,
            onChange:(e) => setCodeinterpreter(e.value)
        },
        {
            title:t('retrieval'),
            tooltip:t('retrievalTooltip'),
            checked:retrieval,
            onChange:(e) => setRetrieval(e.value)
        },
        {
            title:t('imageGenerate'),
            tooltip:t('imageGenerateTooltip'),
            checked:imageGenerate,
            onChange:(e) => setImageGenerate(e.value)
        },
        {
            title:t('transcription'),
            tooltip:t('transcriptionTooltip'),
            checked:transcription,
            onChange:(e) => setTranscription(e.value)
        }
    ];

    // FileUploadコンポーネントの参照を作成
    const fileUploader = useRef(null);
    const formik = useFormik({
        initialValues: {
            name: '',
            instructions: '',
            model: '',
            voiceModel: '',
            functions: '',
            description: '',
            file_ids: '',
            metadata: '',
            share: '',
            avatar: '',
            imageGenerate: '',
            transcription: '',
        },
        validate: (data) => {
            let errors = {};
            setName(data.name);
            setInstructions(data.instructions);
            setFunctions(data.functions);
            setDescription(data.description);
            setFile_ids(data.file_ids);
            setMetadata(data.metadata);
            // setShareSelect(data.share);
            // setModelSelect(data.model);
            setShare(data.share);
            setModel(data.model);
            setVoiceModel(data.voiceModel);
            // setAvatar(data.avatar);
            // setImageGenerate(data.imageGenerate);
            if (!data.name) {
                errors.name = t('nameIsRequired');
            }
            if (data.name.length > 256) {
                errors.name = t('maximumNumberOfCharactersIsUpTo256');
            }
            if (data.description.length > 512) {
                errors.description = t('maximumNumberOfCharactersIsUpTo512');
            }
            if (data.instructions.length > 32768) {
                errors.instructions = t('maximumNumberOfCharactersIsUpTo32768');
            }
            if (!data.instructions) {
                errors.instructions = t('instructionsIsRequired');
            }
            if (!data.description) {
                errors.description = t('descriptionIsRequired');
            }
            if (!data.share) {
                errors.share = t('selectYourPublicSettings');
            }
            if (!data.model) {
                errors.model = t('selectModelSettings');
            }
            if (!data.voiceModel) {
                errors.voiceModel = t('selectModelSettings');
            }
            // console.log(data.share.code);
            // console.log(data.model);
            return errors;
        },
        onSubmit: (data) => {
            sendMessage();
        }
    });
    const modelSelector = () => {
        if(model){
            //モデル設定
            for (let i = 0 ; i < modelsLists.length; i++) {
                // eslint-disable-next-line 
                if(modelsLists[i].id == model){
                    setModelSelect(modelsLists[i]);
                    formik.setFieldValue('model', model,false);
                }
            }
        }
    }
    const voiceModelSelector = () => {
        if(voiceModel){
            //モデル設定
            for (let i = 0 ; i < voiceModels.length; i++) {
                // eslint-disable-next-line 
                if(voiceModels[i].id == voiceModel){
                    setVoiceModelSelect(voiceModels[i]);
                    formik.setFieldValue('voiceModel', voiceModel,false);
                }
            }
        }
    }
    const shareSelector = () => {
        if(share){
            //共有設定
            for (let i = 0 ; i < sharesLists.length; i++) {
                // eslint-disable-next-line 
                if(sharesLists[i].code == share){
                    setShareSelect(sharesLists[i]);
                    formik.setFieldValue('share', share, false);
                }
            }
        }
    }

    const insertAssistdata = (responcedata) =>{
        setName(responcedata['name']);
        formik.setFieldValue('name', responcedata['name'], false);
        const instractionsData = responcedata['instructions'];
        // setInstructions(responcedata['instructions']);
        //functionのinstractionsメッセージを非表示
        let instructionsText = instractionsData.replace(imageGenerateInstruction, '');
        instructionsText = instructionsText.replace(transcriptionInstruction, '');
        formik.setFieldValue('instructions', instructionsText, false);
        setInstructions(instructionsText);
        setModel(responcedata['model']);
        setVoiceModel(responcedata['voiceModel']);
        setFunctions(responcedata['functions']);
        formik.setFieldValue('functions', responcedata['functions'], false);
        setDescription(responcedata['description']);
        formik.setFieldValue('description', responcedata['description'], false);
        formik.setFieldValue('file_ids', responcedata['file_ids'], false);
        setMetadata(responcedata['metadata']);
        formik.setFieldValue('metadata', responcedata['metadata'], false);
        setFile_ids(responcedata['file_ids']);
        setShare(responcedata['share']);
        //共有設定
        for (let i = 0 ; i < sharesLists.length; i++) {
            // eslint-disable-next-line 
            if(sharesLists[i].code == responcedata['share']){
                setShareSelect(sharesLists[i]);
                formik.setFieldValue('share', responcedata['share'], false);
            }
        }
        shareSelector();
        modelSelector();
        voiceModelSelector();

        setAvatar(responcedata['avatar']);
        formik.setFieldValue('avatar', responcedata['avatar'], false);
        // formik.setFieldValue('model', responcedata['model'], false);
        // formik.setFieldValue('share', responcedata['share'], false);
        // selectedItem = shareList().find((item) => item.code === `${responcedata['share']}`);
        // console.log(responcedata['share']);
        // console.log(selectedItem);
        setCount(count + 1)
    }
    const onReset = () => {
        setName('');
        setInstructions('');
        setFunctions('');
        setDescription('');
        setFile_ids('');
        setMetadata('');
        setShareSelect('');
        setModelSelect('');
        setVoiceModelSelect('');
        // setShare('');
        // setModel('');
        // アップロードが完了したらFileUploadコンポーネントをリセット
        fileUploader.current.clear();
    };

    const getModelsList = async () => {
        const sendRequest = async (url) => {
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                setModels(data);
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/modelslist`);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const sendMessage = async () => {
        let tools =[];
        if(codeinterpreter){
            const condeinterpreterCheck={type:'code_interpreter'};
            tools.push(condeinterpreterCheck);
        }
        if(retrieval){
            const retrievalCheck={type:'retrieval'};
            tools.push(retrievalCheck);
        }
        if(imageGenerate){
            tools.push(imageGenerateFunction);
        }
        if(transcription){
            tools.push(transcriptionFunction);
        }
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            // console.log(response);
            if (response.status === 200) {
                //const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                if(selectedAssistant.selectedAssistant==='new'){
                    toast.current.show({ severity: 'success', summary: t('completion'), detail: t('assistantHasBeenGenerated'), life: 3000 });
                    setAvatar();
                    move(true);
                }else{
                    toast.current.show({ severity: 'success', summary: t('completion'), detail: t('assistantChangeCompleted'), life: 3000 });
                    setAvatar();
                    move(true);
                }
            }
        };
        try {
            if(selectedAssistant.selectedAssistant==='new'){
                const data = { token, name, instructions, model, voiceModel, tools, description, file_ids, metadata, share, avatar};
                if(imageGenerate){
                    const generatedInstructionJson = JSON.stringify(imageGenerateInstruction);
                    const instructionText = generatedInstructionJson.slice(1, -1);
                    data.instructions=data.instructions+instructionText;
                }
                if(transcription){
                    const generatedInstructionJson = JSON.stringify(transcriptionInstruction);
                    const instructionText = generatedInstructionJson.slice(1, -1);
                    data.instructions=data.instructions+instructionText;
                }
                await sendRequest(process.env.REACT_APP_API_ASSISTANTS,data);
                formik.resetForm();
                onReset();
            }else{
                if(avatarEdit !== ''){
                    setAvatar(avatarEdit)
                }
                const assistant_id = selectedAssistant.selectedAssistant;
                const data = { assistant_id, token, name, instructions, model, voiceModel, tools, description, file_ids, metadata, share, avatar};

                if(imageGenerate){
                    const generatedInstructionJson = JSON.stringify(imageGenerateInstruction);
                    const instructionText = generatedInstructionJson.slice(1, -1);
                    data.instructions=data.instructions+instructionText;
                }
                if(transcription){
                    const generatedInstructionJson = JSON.stringify(transcriptionInstruction);
                    const instructionText = generatedInstructionJson.slice(1, -1);
                    data.instructions=data.instructions+instructionText;
                }
                // console.log(data);
                await sendRequest(`${process.env.REACT_APP_API_ASSISTANTS}/update`,data);
            }
            //menuのリスト更新
            setAssistantChange(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };
   
    const getAssitant = async(par) =>{
        if(par === 0){
            onReset();
            formik.resetForm();
        }else{
            onReset();
            formik.resetForm();
            const assistant_id = selectedAssistant.selectedAssistant
            if(assistant_id){
                const sendRequest = async (url,data) => {
                    const response = await fetch(url, {
                        method: 'POST', // HTTPメソッド
                        headers: {
                            'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                        },
                        body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                    });
                    if (response.status === 200) {
                        const list = await response.json();
                        setCodeinterpreter(false);
                        setRetrieval(false);
                        // console.log(list)
                        if(list[0].tools){
                            for(let i = 0; i < list[0].tools.length; i++){
                                const type = list[0].tools[i].type;
                                if(type === 'code_interpreter') setCodeinterpreter(true);
                                if(type === 'retrieval') setRetrieval(true);
                                if(type === 'function'){
                                    const toolsFunction = list[0].tools[i].function;
                                    if(toolsFunction.name === 'createImage') setImageGenerate(true);
                                    if(toolsFunction.name === 'createTranscription') setTranscription(true);
                                }
                            }
                        }
                        await insertAssistdata(list[0]);
                        // console.log(list[0]);
                         setCount(count + 1)
                    }
                };
                try {
                    const data = { assistant_id };
                    sendRequest(`${process.env.REACT_APP_API_ASSISTANTS}/assistant`,data);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
    }

    const getSpeech = async() =>{
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const voiceFileName = await response.json();
                setVoiceTestFile(`${process.env.REACT_APP_DOWNLOADPATH}/files/createmodel/${voiceFileName}`);
                // console.log(voiceFileName);
            }
        };
        try {
            const voiceText = voiceTestText;
            const data = { token, voiceModel, voiceText };
            sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/speech`,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        shareSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[share,sharesLists]);

    useEffect(() => {
        modelSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[model,modelsLists]);

    useEffect(() => {
        voiceModelSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[voiceModel]);

    useEffect(() => {
        const role = sessionStorage.getItem('role');
        let options = [];
        if (role === '3001') {
            options = [
                { name: t('forMayself'), code: '0'},
                { name: t('shareWithFriends'), code: '1' },
                { name: t('sharePublicly'), code: '2' },
                { name: t('shareSystem'), code: '4' }
            ];
        } else if (role === '3002') {
            options = [
                { name: t('forMayself'), code: '0' },
                { name: t('shareWithFriends'), code: '1' },
            ];
        } else if (role === '3003') {
            options = [
                { name: t('forMayself'), code: '0' },
                { name: t('shareWithFriends'), code: '1' },
                { name: t('sharePublicly'), code: '2' },
            ];
        } else {
          // 初期状態
            options = [
                { name: t('forMayself'), code: '0' },
                { name: t('shareWithFriends'), code: '1' }
            ];
        }
        setSharesLists(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        if(selectedAssistant.selectedAssistant !== 'top' && selectedAssistant.selectedAssistant !== 'friend'){
            getModelsList();
            setVoiceTestText(t('sampleVoiceText'));
            setVoiceTestFile('');
            if(selectedAssistant.selectedAssistant !== 'new'){
                setAvatarEdit('')
                setCodeinterpreter(false);
                setRetrieval(false);
                setImageGenerate(false);
                setTranscription(false);
                getAssitant(1);
            }else{
                setModel('');
                setVoiceModel('');
                setShare('');
                setCodeinterpreter(false);
                setRetrieval(false);
                setImageGenerate(false);
                setTranscription(false);
                getAssitant(0);
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedAssistant]);
    // GTPのModelsを取得したらリストを作る
    useEffect(() =>{
        const { reply } = models; 
        const filteredModels = reply.filter((model) => model.id.startsWith("gpt-"));
        setModelsLists(filteredModels);
    },[models]);

    useEffect(() =>{
        setVoiceTestFile('');
    },[voiceModelSelect])

    const titleContent = (title)=>{
        return(
         <>
         <span style={{marginLeft:'10px',fontWeight:'bold'}}>{title}</span>
         </>
        )
    }

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const [tabs] = useState([
        {
            header: t('name'),
            children: <p className="m-0">{t('nameDocument')}</p>
        },
        {
            header: t('instructions'),
            children: <p className="m-0">{t('instructionsDocument')} </p>
        },
        {
            header: t('model'),
            children: <p className="m-0">{t('modelDocument')}</p>
        },
        {
            header: t('description'),
            children: <p className="m-0">{t('descriptionDocument')}</p>
        },
        {
            header: t('pubulicSetting'),
            children: <p className="m-0">{t('pubulicSettingDocument')}</p>
        },
        {
            header: t('avatar'),
            children: <p className="m-0">{t('avatarDocument')}</p>
        },
        {
            header: t('tools'),
            children: <p className="m-0">{t('toolsDocument')}</p>
        }
    ]);


    const createDynamicTabs =() =>{
        return tabs.map((tab, i) => {
            return (
                <AccordionTab
                    pt={{
                        headerAction:{
                            style:{backgroundColor: 'var(--primary-600)', color:'var(--primary-color-text)'}
                        },
                        content:{
                            style:{backgroundColor: 'var(--primary-700)', color:'var(--primary-color-text)'}
                        }
                    }}
                    key={tab.header} header={tab.header} disabled={tab.disabled} 
                    style={{
                        width:'100%', padding: '0px 20px', textAlign:'left', whiteSpace:'pre-wrap'
                        }}>
                <span>{tab.children}</span>
                </AccordionTab>
            );
        });
    };

    const onUploadAvatar = (e) => {
        // ファイルデータを取得
        const response = JSON.parse(e.xhr.response);
        setAvatar(response.file);
        // console.log('アップロード先URL:', response.file);
    };
    const onUploadAvatarEdit = (e) => {
        // ファイルデータを取得
        const response = JSON.parse(e.xhr.response);
        setAvatarEdit(response.file);
        // console.log('アップロード先URL:', response.file);
    };
    const onRemoveAvatarEdit = (e) => {
        setAvatarEdit('');
        // console.log('アップロード先URL:', response.file);
    };

    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() => move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }

    const voiceModels = [
        {
            id:'alloy'
        },
        {
            id:'echo'
        },
        {
            id:'fable'
        },
        {
            id:'onyx'
        },
        {
            id:'nova'
        },
        {
            id:'shimmer'
        }
    ]
    const voiceTestSpeech = () =>{
        if(voiceModelSelect && voiceTestText.length > 0){
            getSpeech();
        }
    }
    const onVoiceLoad = () =>{
        if (voiceTestRef.current) {
            voiceTestRef.current.play();
        }
    }
    const voiceTestPlay = () =>{
        if (voiceTestRef.current) {
            voiceTestRef.current.play();
        }
    }
    const voiceTestStop = () =>{
        if (voiceTestRef.current) {
            voiceTestRef.current.pause();
            voiceTestRef.current.currentTime=0;
        }
    }
    const voiceContent = () =>{
        return (
            <Card
                title={t('voice')}
                pt={{
                    root: {
                        style:{
                            backgroundColor: 'var(--primary-400)',
                            border: '0.8px solid',
                            borderColor: 'var(--gray-800)',
                            color:'var(--primary-color-text)',
                            margin:'10px'
                        }
                    },
                    title: {
                        style:{
                            fontSize: '18px',
                            textAlign:'left',
                        }
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        height: '100%',
                        gap: '10px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Dropdown 
                            pt={{
                                input:{
                                    style:{
                                        backgroundColor: 'var(--primary-300)', 
                                        color:'var(--primary-color-text)'
                                    }
                                },
                                trigger:{
                                    style:{
                                        backgroundColor: 'var(--primary-300)', 
                                        color:'var(--primary-color-text)'
                                    }
                                },
                                panel:{
                                    style:{
                                        backgroundColor: 'var(--primary-100)', 
                                        color:'var(--primary-color-text)'
                                    }
                                },
                                item:{
                                    style:{
                                        backgroundColor: 'var(--primary-100)', 
                                        color:'var(--primary-color-text)'
                                    }
                                }
                            }}
                            value={voiceModelSelect} 
                            onChange={(e) => {
                                formik.setFieldValue('voiceModel', e.target.value);
                                setVoiceModel(e.target.value.id);
                            }} 
                            options={voiceModels}
                            optionLabel="id" 
                            placeholder={t('voiceModel')}
                            style={{
                                backgroundColor: 'var(--primary-500)'
                            }} 
                        /> 
                        {getFormErrorMessage('voiceModel')}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            gap: '10px'
                        }}
                    >
                        <InputText 
                            value={voiceTestText}
                            onChange={(e) => {
                                setVoiceTestText(e.target.value);
                            }}
                            style={{
                                backgroundColor: 'var(--primary-300)', 
                                color:'var(--primary-color-text)',
                                width:'100%'
                            }}
                        />
                        <audio ref={voiceTestRef}
                            src={voiceTestFile}
                            onLoadedData={onVoiceLoad}
                            
                        />
                        <Button
                            type='button'
                            icon='pi pi-volume-up'
                            onClick={voiceTestSpeech}
                            pt={{
                                root: {
                                    style:{
                                        boxShadow: 'none',
                                        border:'none'
                                    }
                                }
                            }}
                        />
                        <Button
                            type='button'
                            icon='pi pi-play'
                            onClick={voiceTestPlay}
                            disabled={!voiceTestFile? 'disabled': ''}
                            pt={{
                                root: {
                                    style:{
                                        boxShadow: 'none',
                                        border:'none'
                                    }
                                }
                            }}
                        />
                        <Button
                            type='button'
                            icon='pi pi-stop'
                            onClick={voiceTestStop}
                            disabled={!voiceTestFile? 'disabled': ''}
                            pt={{
                                root: {
                                    style:{
                                        boxShadow: 'none',
                                        border:'none'
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </Card>
        )
    }
    const functionToolContent = () =>{
        const itemsContent = functionToolArray.map((item,index) => {
            return (
                <div 
                    style={{
                    alignItems:'right', 
                    display:'flex',
                    gap:'20px'
                    }}>
                    <div
                        style={{
                            minWidth:'180px',
                            display: 'flex',
                            alignContent: 'center',
                            gap: '10px'
                        }}>
                        <InputSwitch 
                            key={index}
                            tooltip= {item.tooltip}
                            checked= {item.checked} 
                            onChange= {item.onChange}
                        />
                        <div
                            style={{
                                minWidth:'150px',
                                textAlign: 'left',
                            }}
                        >
                            {item.title}
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <Card
                key= 'editPanel'
                title={t('tools')}
                pt={{
                    root: {
                        style:{
                            backgroundColor: 'var(--primary-400)',
                            border: '0.8px solid',
                            borderColor: 'var(--gray-800)',
                            color:'var(--primary-color-text)',
                            margin:'10px'
                        }
                    },
                    title: {
                        style:{
                            fontSize: '18px',
                            textAlign:'left',
                        }
                    }
                }}
            >
                <div className="p-fluid" 
                    style={{
                            display:'flex', 
                            justifyContent:'space-between', 
                            gap: '10px',
                            flexWrap: 'wrap',
                        }}>
                    {itemsContent}
                </div>
            </Card>
        )
    }
    const contents = () => {
        if(selectedAssistant.selectedAssistant === 'top'){
            // アシスタント作成待機画面
            return (
                <div style={{display:'block', width:'100%',height:'100%'}}>
                    <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                        <Toolbar start={backButtonContent()} center={titleContent(t('assistantEditorManual'))}
                            style={{ width: '100%', height: '60px',
                            paddingTop:'10px',
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                    </div>
                    <ScrollPanel className="chatScroll" 
                        style={{ 
                            width: '100%', height: '100%', padding: '10px 10px 70px 10px', 
                            overflowY: 'auto', overflowX: 'hidden',
                            backgroundColor:'var(--primary-800)' }}>
                        <div style={{ display: 'block',  justifyContent: 'center', width:'100%'}}>
                            <Card 
                                title={t('createYourOwnAssistant')} 
                                style={{width:'100%', padding: '0px 20px', textAlign:'center',
                                backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)'}}>
                            </Card>
                                <Accordion  style={{width:'100%', padding: '20px 20px 10px', textAlign:'left',
                            }}>
                                    {createDynamicTabs()}
                                </Accordion>
                        </div>  
                    </ScrollPanel>
                </div>
            )
        } else if(selectedAssistant.selectedAssistant === 'friend'){
            // アシスタント作成待機画面
            return (
                <div style={{display:'block', width:'100%',height:'100%'}}>
                    <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                        <Toolbar start={backButtonContent()} center={titleContent(t('freindsSearch'))}
                            style={{ width: '100%', height: '60px', 
                            paddingTop:'10px',
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                    </div>
                    <ScrollPanel className="chatScroll" style={{ width: '100%', height: '100%', padding: '10px 10px 70px 10px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div style={{ display: 'block',  justifyContent: 'center', width:'100%'}}>
                            <Card title={t('underDdevelopmentStayTunedForTheNearFuture')} style={{width:'100%', padding: '0px 20px', textAlign:'center'}}>
                            </Card>
                        </div>  
                    </ScrollPanel>
                </div>
            )
        } else if(selectedAssistant.selectedAssistant === 'new'){
            // アシスタント作成
            return (
                <div style={{display:'block', width:'100%',height:'100%'}}>
                    <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                        <Toolbar start={backButtonContent()} 
                            center={titleContent(t('makeAnAssistant'))}
                            style={{ width: '100%', height: '60px',
                            paddingTop:'10px',
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                    </div>
                    <ScrollPanel className="chatScroll" style={{ width: '100%', height: '100%', padding: '10px 10px 70px 10px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                            <Toast ref={toast} />
                                <form onSubmit={formik.handleSubmit} style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                                    <Card 
                                    style={{width:'100%', padding: '0px 20px', textAlign:'center', 
                                        backgroundColor: 'var(--primary-400)', color:'var(--primary-color-text)'}}>
                                        <div className="p-fluid">
                                            <InputText
                                                inputid="name"
                                                name="name"
                                                value={name}
                                                placeholder={t('name')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('name', e.target.value);
                                                }}
                                                autoComplete="name"
                                                style={{
                                                    backgroundColor: 'var(--primary-300)', 
                                                    color:'var(--primary-color-text)'
                                                }} 
                                            />
                                            {getFormErrorMessage('name')}
                                        </div>
                                        <div className="p-fluid">
                                            <InputTextarea 
                                                value={instructions} 
                                                inputid="instructions"
                                                placeholder={t('instructions')}
                                                onChange={(e) => {
                                                formik.setFieldValue('instructions', e.target.value);
                                                }} 
                                                 rows={5} 
                                                 style={{
                                                     backgroundColor: 'var(--primary-300)', 
                                                     color:'var(--primary-color-text)'
                                                 }} />
                                            {getFormErrorMessage('instructions')}
                                        </div>
                                        <div className="p-fluid">
                                            <InputText
                                                inputid="description"
                                                name="description"
                                                value={description}
                                                placeholder={t('description')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('description', e.target.value);
                                                }}
                                                autoComplete="description" 
                                                style={{
                                                    backgroundColor: 'var(--primary-300)', 
                                                    color:'var(--primary-color-text)'
                                                }} />
                                            {getFormErrorMessage('description')}
                                        </div>
                                        <div style={{gap:'10px', display:'float'}}  className="p-fluid">
                                            <Dropdown
                                                pt={{
                                                    input:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    trigger:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    panel:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    item:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    }
                                                }}
                                                value={shareSelect} 
                                                onChange={(e) => {
                                                    formik.setFieldValue('share', e.target.value);
                                                    setShare(e.target.value.code);
                                                }} 
                                                options={sharesLists}
                                                optionLabel="name" 
                                                placeholder={t('pubulicSetting')}
                                                style={{margin:'0px 10px',
                                                    backgroundColor: 'var(--primary-500)'
                                                }} />
                                            {getFormErrorMessage('share')}
                                            <Dropdown 
                                                pt={{
                                                    input:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    trigger:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    panel:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    item:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    }
                                                }}
                                                value={modelSelect} 
                                                onChange={(e) => {
                                                    formik.setFieldValue('model', e.target.value);
                                                    setModel(e.target.value.id);
                                                }} 
                                                options={modelsLists}
                                                optionLabel="id" 
                                                placeholder={t('model')}
                                                style={{margin:'0px 10px',
                                                    backgroundColor: 'var(--primary-500)'
                                                }} /> 
                                            {getFormErrorMessage('model')}
                                        </div>
                                        {functionToolContent()}
                                        {voiceContent()}
                                        <div className="p-fluid">
                                            <InputTextarea 
                                            value={functions} 
                                            placeholder={t('function')}
                                            onChange={(e) => {
                                                formik.setFieldValue('functions', e.target.value);
                                            }}
                                            disabled
                                            rows={5} 
                                            style={{
                                                backgroundColor: 'var(--primary-300)', 
                                                color:'var(--primary-color-text)',
                                                display:'none'
                                            }}/>
                                            {getFormErrorMessage('functions')}
                                        </div>
                                        <FileUpload
                                            pt={{
                                                buttonbar:{
                                                    style:{
                                                        backgroundColor: 'var(--primary-300)', 
                                                        color:'var(--primary-color-text)'
                                                    }
                                                },
                                                content:{
                                                    style:{
                                                        backgroundColor: 'var(--primary-200)', 
                                                        color:'var(--primary-color-text)'
                                                    }
                                                    
                                                }
                                            }}
                                            name="avatar"
                                            ref={fileUploader} 
                                            url={`${process.env.REACT_APP_API_USERS}/avatarupload`} 
                                            auto={true} 
                                            multiple={false} 
                                            chooseLabel={t('choose')}
                                            accept="image/jpg,image/png,image/gif,image/bmp" 
                                            maxFileSize={1000000} 
                                            onUpload={onUploadAvatar}
                                            emptyTemplate={<p className="m-0">{t('dragAndDropFileToHereToUpload')}</p>} 
                                        />
                                        <div style={{padding:'20px'}}>
                                            <Button label={t('create')} type="submit" />
                                        </div>
                                    </Card>
                                </form>
                        </div>
                    </ScrollPanel>
                </div>
            )
        }else{
            // アシスタント編集
            return (
                <div style={{display:'block', width:'100%',height:'100%'}}>
                    <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                        <Toolbar start={backButtonContent()} 
                            center={titleContent(t('editAssistant'))}
                            style={{ width: '100%', height: '60px', 
                            paddingTop:'10px',
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                    </div>
                    <ScrollPanel className="chatScroll" style={{ width: '100%', height: '100%', padding: '10px 10px 70px 10px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                            <Toast ref={toast} />
                                <form onSubmit={formik.handleSubmit} style={{ display: 'flex',  justifyContent: 'center', width:'100%'}}>
                                    <Card 
                                    style={{width:'100%', padding: '0px 20px', textAlign:'center', 
                                        backgroundColor: 'var(--primary-400)', color:'var(--primary-color-text)'}}>
                                        <div className="p-fluid">
                                            <InputText
                                                inputid="name"
                                                name="name"
                                                value={name}
                                                placeholder={t('name')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('name', e.target.value);
                                                }}
                                                autoComplete="name"
                                                style={{
                                                    backgroundColor: 'var(--primary-300)', 
                                                    color:'var(--primary-color-text)'
                                                }} 
                                            />
                                            {getFormErrorMessage('name')}
                                        </div>
                                        <div className="p-fluid">
                                            <InputTextarea 
                                                value={instructions} 
                                                inputid="instructions"
                                                placeholder={t('instructions')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('instructions', e.target.value);
                                                }} 
                                                 rows={5} 
                                                 style={{
                                                     backgroundColor: 'var(--primary-300)', 
                                                     color:'var(--primary-color-text)'
                                                 }} />
                                            {getFormErrorMessage('instructions')}
                                        </div>
                                        <div className="p-fluid">
                                            <InputText
                                                inputid="description"
                                                name="description"
                                                value={description}
                                                placeholder={t('description')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('description', e.target.value);
                                                }}
                                                autoComplete="description" 
                                                style={{
                                                    backgroundColor: 'var(--primary-300)', 
                                                    color:'var(--primary-color-text)'
                                                }} />
                                            {getFormErrorMessage('description')}
                                        </div>
                                        <div style={{gap:'10px', display:'float'}}  className="p-fluid">
                                            <Dropdown
                                                pt={{
                                                    input:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    trigger:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    panel:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    item:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    }
                                                }}
                                                value={shareSelect} 
                                                onChange={(e) => {
                                                    formik.setFieldValue('share', e.target.value);
                                                    setShare(e.target.value.code);
                                                }} 
                                                options={sharesLists}
                                                optionLabel="name" 
                                                placeholder={t('pubulicSetting')}
                                                style={{margin:'0px 10px',
                                                    backgroundColor: 'var(--primary-500)'
                                                }} />
                                            {getFormErrorMessage('share')}
                                            <Dropdown 
                                                pt={{
                                                    input:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    trigger:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    panel:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    item:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-100)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    }
                                                }}
                                                value={modelSelect} 
                                                onChange={(e) => {
                                                    formik.setFieldValue('model', e.target.value);
                                                    setModel(e.target.value.id);
                                                }} 
                                                options={modelsLists}
                                                optionLabel="id" 
                                                placeholder={t('model')}
                                                style={{margin:'0px 10px',
                                                    backgroundColor: 'var(--primary-500)'
                                                }} /> 
                                            {getFormErrorMessage('model')}
                                        </div>
                                        {functionToolContent()}
                                        {voiceContent()}
                                        <div className="p-fluid">
                                            <InputTextarea 
                                            value={functions} 
                                            placeholder={t('function')}
                                            onChange={(e) => {
                                                formik.setFieldValue('functions', e.target.value);
                                            }}
                                            disabled
                                            rows={5} 
                                            style={{
                                                backgroundColor: 'var(--primary-300)', 
                                                color:'var(--primary-color-text)',
                                                display:'none',
                                            }}/>
                                            {getFormErrorMessage('functions')}
                                        </div>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <Image src={`uploads/${avatar}`} width={'100%'} style={{padding:'10px'}}/>
                                            <FileUpload
                                                pt={{
                                                    buttonbar:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-300)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                    },
                                                    content:{
                                                        style:{
                                                            backgroundColor: 'var(--primary-200)', 
                                                            color:'var(--primary-color-text)'
                                                        }
                                                        
                                                    }
                                                }}
                                                style={{padding:'10px',width:'100%'}}
                                                name="avatar"
                                                ref={fileUploader} 
                                                url={`${process.env.REACT_APP_API_USERS}/avatarupload`} 
                                                auto={true} 
                                                multiple={false} 
                                                chooseLabel={t('choose')}
                                                accept="image/jpg,image/png,image/gif,image/bmp" 
                                                maxFileSize={1000000} 
                                                onUpload={onUploadAvatarEdit}
                                                onRemove ={onRemoveAvatarEdit}
                                                emptyTemplate={<p className="m-0">{t('dragAndDropFileToHereToUpload')}</p>} 
                                            />
                                        </div>
                                        <div style={{padding:'20px'}}>
                                            <Button label={t('edit')} type="submit"/>
                                        </div>
                                    </Card>
                                </form>
                        </div>
                    </ScrollPanel>
                </div>
            )
        }
    }
    return contents()
}
export default AssistantEditor