// XBoard.js
import React, { useEffect, useState } from 'react';
import { Splitter, SplitterPanel } from 'primereact/splitter';

const XBoard = () => {
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const calculateElementHeight = () => {
      // ビューポートの高さを取得
      const viewportHeight = window.innerHeight;
      // 利用可能な高さを計算
      const availableHeight = viewportHeight;
      // 要素に計算された高さを適用
      setElementHeight(availableHeight);
    };
    // コンポーネントがマウントされた時に高さを計算
    calculateElementHeight();
    // ウィンドウのリサイズ時に再計算
    window.addEventListener('resize', calculateElementHeight);
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', calculateElementHeight);
      document.body.style.height = calculateElementHeight;
    }
  }, []);

  if(window.innerWidth < 768){
    //スマホ画面
    return (
      <>
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          className="center-panel-sm" 
          style={{ 
            width:'100%',
            height: `${elementHeight-50}px`,
            backgroundColor:'var(--primary-400)'
          }} 
        >
          {/* 中セクション（可変） */}
        </SplitterPanel>
        <SplitterPanel 
          className="right-panel-sm" 
          style={{ 
            display:'none',
            width:'100%',
            height: `${elementHeight-50}px`,
            backgroundColor:'var(--primary-900)'
          }}>
          {/* 右セクション（残りのスペース） */}
        </SplitterPanel>
      </Splitter>
      </>
    );
  }else{
    //PC tablet画面
    return (
      <>
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          size={20}
          className="center-panel" 
          style={{ 
            height: `${elementHeight}px`,
            backgroundColor:'var(--primary-400)'}}>
          {/* 中セクション（可変） */}
        </SplitterPanel>
        <SplitterPanel className="right-panel" style={{ height: `${elementHeight}px`, backgroundColor:'var(--primary-900)'}}>
          {/* 右セクション（残りのスペース） */}
        </SplitterPanel>
      </Splitter>
      </>
    );
  }
};

export default XBoard;