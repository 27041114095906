// AssistantEditorBoard.js
import React, { useEffect, useState } from 'react';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import AssistantEditorList from './AssistantEditorList';
import AssistantEditor from './AssistantEditor';

const AssistantEditorBoard = () => {
  const [elementHeight, setElementHeight] = useState(0);
  const [selectAction, setSelectAction] = useState('top');
  const [ centerPanelSm, setCenterPanelSm ] = useState();
  const [ rightPanelSm, setRightPanelSm ] = useState();
  const [ centerPanel, setCenterPanel ] = useState();
  const [ rightPanel, setRightPanel ] = useState();

  useEffect(() => {
    if(window.innerWidth < 768){
      setSelectAction(null);
    }
    const calculateElementHeight = () => {
      // ビューポートの高さを取得
      const viewportHeight = window.innerHeight;
      // 利用可能な高さを計算
      const availableHeight = viewportHeight;
      // 要素に計算された高さを適用
      setElementHeight(availableHeight);
      setCenterPanelSm(document.querySelector('.center-panel-sm'));
      setCenterPanel(document.querySelector('.center-panel'));
      setRightPanelSm(document.querySelector('.right-panel-sm'));
      setRightPanel(document.querySelector('.right-panel'));
    };
    // コンポーネントがマウントされた時に高さを計算
    calculateElementHeight();
    // ウィンドウのリサイズ時に再計算
    window.addEventListener('resize', calculateElementHeight);
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', calculateElementHeight);
      document.body.style.height = calculateElementHeight;
    }
  }, []);

  const moveRightWindow =() =>{
    if(window.innerWidth < 768){
      //スマホ画面
      if(rightPanelSm)rightPanelSm.style.display = 'block';
      if(centerPanelSm)centerPanelSm.style.display = 'none';
    }else{
      //PC Tablet画面
      if(rightPanel)rightPanel.style.display = 'block';
      if(centerPanelSm)centerPanelSm.style.display = 'block';
      if(centerPanel)centerPanel.style.display = 'block';
    }
  }

  const backPanelMove = (back) =>{
    if(back){
      setSelectAction('top');
      if(window.innerWidth < 768){
        //スマホ画面
        if(rightPanelSm)rightPanelSm.style.display = 'none';
        if(centerPanelSm)centerPanelSm.style.display = 'block';
    }
    }
  }

  useEffect(()=>{
    if(selectAction)moveRightWindow();
 /* eslint-disable */
  },[selectAction])

  if(window.innerWidth < 768){
    //スマホ画面
    return (
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          className="center-panel-sm" 
          style={{ 
            width:'100%',
            height: `${elementHeight}px`,
            backgroundColor:'var(--primary-400)'}}>
          {/* 中セクション（可変） */}
          <AssistantEditorList onChange={setSelectAction} />
        </SplitterPanel>
        <SplitterPanel 
          className="right-panel-sm" 
          style={{ 
            display:'none',
            height: `${elementHeight-50}px`, 
            backgroundColor:'var(--primary-900)'
          }}>
          {/* 右セクション（残りのスペース） */}
          <AssistantEditor 
            selectedAssistant={selectAction}
            move={backPanelMove}
          />
        </SplitterPanel>
      </Splitter>
    );
  }else{
    //PC tablet画面
    return (
      <Splitter 
        gutterSize={2} 
        style={{ flex: 1 }}
        pt={{
          gutterhandler:{
            style:{
              backgroundColor:'var(--gray-600)'
            }
          }
        }}
      >
        <SplitterPanel 
          size={20}
          className="center-panel" 
          style={{ 
            height: `${elementHeight}px`,
            backgroundColor:'var(--primary-400)'}}>
          {/* 中セクション（可変） */}
          <AssistantEditorList onChange={setSelectAction} />
        </SplitterPanel>
        <SplitterPanel className="right-panel" style={{ height: `${elementHeight}px`, backgroundColor:'var(--primary-900)'}}>
          {/* 右セクション（残りのスペース） */}
          <AssistantEditor 
            selectedAssistant={selectAction}
            move={backPanelMove}
           />
        </SplitterPanel>
      </Splitter>
    );
  }
  
};

export default AssistantEditorBoard;