// SetupUser.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';

const SetupUser = ({move}) => {
    const { t } = useTranslation();

    const titleContent = (title)=>{
        return(
             <span style={{marginLeft:'10px',fontWeight:'bold'}}>{title}</span>
        )
     }

    const userContent = (
        <>
            <div className="p-fluid" style={{ justifyContent: 'center', width: '100%', display: 'flex', textAlign: 'center'}}>
                {t('underDdevelopmentStayTunedForTheNearFuture')}
            </div>
        </>
    )


    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() =>move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }
    return (
        <div style={{ alignItems: 'self-start', justifyContent: 'center', height: '100%', textAlign: 'center' }}>
            <div
                style={{
                    height:'60px'
                }}>
                {/* タイトルセクション */}
                <Toolbar start={backButtonContent()} center={titleContent(t('editUserInfomation'))}
                    style={{ width: 'auto', height: '60px', 
                    paddingTop:'10px',
                    backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                />
            </div>
                <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'60px',
                                        backgroundColor: 'var(--primary-900)' }}>
                    <div style={{ 
                        display:'flex', 
                        flexDirection:'column', 
                        textAlign:'left', 
                        gap:'20px',
                        padding:'20px'
                        }} >
                        <Fieldset style={{textAlign:'left',margin:'20px', backgroundColor:'var(--primary-700)', color:'var(--primary-color-text)'}}>
                                {userContent} 
                        </Fieldset>
                    </div>
                </ScrollPanel>
        </div>
    );
};

export default SetupUser;