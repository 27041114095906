import React, { useEffect, useRef, useContext, useState } from 'react';
import { StateSystemChange } from '../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { SpeedDial } from 'primereact/speeddial';
import { useTranslation } from 'react-i18next';

const Menu = () => {
    const { t } = useTranslation();
    const role = useRef('3002'); 
    const location = useLocation();
    const { setSystemChange } = useContext(StateSystemChange);
    const token = localStorage.getItem('token');
    const [ mainMenuContents, setMainMenuContents ] =  useState();
    const [ systemMenuContents, setSystemMenuContents ] =  useState();
    // let role = sessionStorage.getItem("role");

    useEffect(() => {
        // SetSessionStrage();
        role.current = sessionStorage.getItem("role");
        createMenu();
        // eslint-disable-next-line
    }, [location]);
    

    const sendDark = (dark) => {
        const data = { token, dark };
        const sendRequest = async (url) => {
            await fetch(url, {
                method: 'PUT', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
        };
        try {
            sendRequest(`${process.env.REACT_APP_API_SYSTEM}/setdark`);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const navigate = useNavigate();
    const navigateToDashboard = () => {
        navigate('/dashboard');
    };
    const navigateToChat = () => {
        navigate('/chat');
    };
    const navigateToAssistants = () => {
        navigate('/assistants');
    };
    const navigateToAssistantEditor = () => {
        navigate('/assistanteditor');
    };
    const navigateToBookmark = () => {
        navigate('/bookmark');
    };
    const navigateToBriefing = () => {
        navigate('/briefing');
    };
    const navigateToShop = () => {
        navigate('/shop');
    };
    const navigateToWallet = () => {
        navigate('/wallet');
    };
    const navigateToMail = () => {
        navigate('/mail');
    };
    const navigateToInstagram = () => {
        navigate('/instagram');
    };
    const navigateToFacefook = () => {
        navigate('/facebook');
    };
    const navigateToTelegram = () => {
        navigate('/telegram');
    };
    const navigateToX = () => {
        navigate('/x');
    };
    const navigateToYoutube = () => {
        navigate('/youtube');
    };
    const navigateToQrcode = () => {
        navigate('/qrcode');
    };
    const navigateToFlash = () => {
        // navigate('/flash');
        let dark;
        if(sessionStorage.getItem('darkmode')==='dark'){
            dark = 'light';
        }else{
            dark = 'dark';
        }
        sessionStorage.setItem('darkmode', dark);
        sendDark(dark);
        //App.js内に変更した事を投げる
        setSystemChange(true);
    };
    const navigateToCube = () => {
        navigate('/cube');
    };
    const navigateToHelp = () => {
        navigate('/help');
    };
    const navigateToSetup = () => {
        navigate('/setup');
    };

    //level of
    // 0 = All user
    // 1 = Payed user
    // 2 = Admin
    // 100 = System menu

    const menuItems = [
        {
            label: t('dashboard'),
            icon: 'pi pi-desktop',
            command: navigateToDashboard,
            level: 0,
            smMenu:0
        },
        {
            label: t('chat'),
            icon: 'pi pi-comments',
            command: navigateToChat,
            level: 0,
            smMenu:1
        },
        {
            label: t('assistants'),
            icon: 'pi pi-user',
            command: navigateToAssistants,
            level: 0,
            smMenu:2
        },
        {
            label: t('assistanteditor'),
            icon: 'pi pi-user-plus',
            command: navigateToAssistantEditor,
            level: 0,
            smMenu:2
        },
        {
            label: t('bookmark'),
            icon: 'pi pi-bookmark',
            command: navigateToBookmark,
            level: 0,
            smMenu:3
        },
        {
            label: t('briefing'),
            icon: 'pi pi-briefcase',
            command: navigateToBriefing,
            level: 1,
            smMenu: 3
        },
        {
            label: t('mail'),
            icon: 'pi pi-envelope',
            command: navigateToMail,
            level: 1,
            smMenu: 4
        },
        {
            label: t('instagram'),
            icon: 'pi pi-instagram',
            command: navigateToInstagram,
            level: 1,
            smMenu: 4
        },
        {
            label: t('facebook'),
            icon: 'pi pi-facebook',
            command: navigateToFacefook,
            level: 1,
            smMenu: 4
        },
        {
            label: t('telegram'),
            icon: 'pi pi-telegram',
            command: navigateToTelegram,
            level: 1,
            smMenu: 4
        },
        {
            label: t('x'),
            icon: 'pi pi-twitter',
            command: navigateToX,
            level: 1,
            smMenu: 4
        },
        {
            label: t('youtube'),
            icon: 'pi pi-youtube',
            command: navigateToYoutube,
            level: 1,
            smMenu: 4
        },
        {
            label: t('setUp'),
            icon: 'pi pi-bars',
            command: navigateToSetup,
            level: 100,
            smMenu:5
        },
        {
            label: t('wallet'),
            icon: 'pi pi-money-bill',
            command: navigateToWallet,
            level: 100,
            smMenu:5
        },
        {
            label: t('qr'),
            icon: 'pi pi-qrcode',
            command: navigateToQrcode,
            level: 100,
            smMenu:5
        },
        {
            label: t('shop'),
            icon: 'pi pi-shopping-cart',
            command: navigateToShop,
            level: 100,
            smMenu:5
        },
        {
            label: t('darkMode'),
            icon: 'pi pi-bolt',
            command: navigateToFlash,
            level: 100,
            smMenu:5
        },
        {
            label: t('help'),
            icon: 'pi pi-question-circle',
            command: navigateToHelp,
            level: 100,
            smMenu:5
        },
        {
            label: t('cube'),
            icon: 'pi pi-box',
            command: navigateToCube,
            level: 100,
            smMenu:5
        },
    ];
    const createMenu = () =>{
        setMainMenuContents('');
        setSystemMenuContents('');
        // eslint-disable-next-line
       const meinMenuContentsMap = menuItems.map((menuItem,index) =>{
        let visibilityLevel = 0;
        switch(role.current){
            //管理者
            case '3001':visibilityLevel=99;
            break;
            //一般
            case '3002':visibilityLevel=0;
            break;
            //一般有料
            case '3003':visibilityLevel=1;
            break;
            //3004:未アクティベート
            case '3004':
            default:visibilityLevel=0;
            break;
        }
            if(menuItem.level <= visibilityLevel){
                return (
                    <Button 
                        key={index}
                        icon={menuItem.icon}
                        text
                        aria-label={menuItem.label}
                        tooltip={menuItem.label}
                        onClick={menuItem.command}
                        style={{
                            color:'var(--primary-color-text)',
                            width:'50px',
                            height:'50px',
                            margin:'0',
                            padding:'0'
                        }}
                        pt={{
                            root:{
                                style:{
                                    boxShadow:'none'
                                }
                            },
                            icon:{
                                style:{
                                    fontSize:'1.4em'
                                }
                            }
                        }}
                    />
                )
            }
        })
        // eslint-disable-next-line
        const systemMenuContentsMap = menuItems.map((menuItem,index) =>{
             if(menuItem.level === 100){
                 return (
                     <Button 
                        key={index}
                        icon={menuItem.icon}
                        text
                        aria-label={menuItem.label}
                        tooltip={menuItem.label}
                        onClick={menuItem.command}
                        style={{
                            color:'var(--primary-color-text)',
                            width:'50px',
                            height:'50px',
                            margin:'0',
                            padding:'0'
                        }}
                        pt={{
                            root:{
                                style:{
                                    boxShadow:'none'
                                }
                            },
                            icon:{
                                style:{
                                    fontSize:'1.4em'
                                }
                            }
                        }}
                     />
                 )
             }
         })
        setMainMenuContents(
                <ScrollPanel 
                    key='mainMenu'
                    style={{ 
                        width: '100%', height: '100%', 
                        overflowY:'auto',overflowX:'hidden',
                        backgroundColor:'var(--primary-800)'
                    }}>
                    {meinMenuContentsMap}
                </ScrollPanel>
        )
        setSystemMenuContents(
            <ScrollPanel 
                key='systemMenu'
                style={{ 
                    width: '100%', height: '150px', 
                    overflowY:'auto',
                    backgroundColor:'var(--primary-900)'
                }}>
                {systemMenuContentsMap}
            </ScrollPanel>
        )
    }

    const menuItemForSmatphone = (i)=>{
        const itemsCount = menuItems.filter(item => item.smMenu === i).length;
        if(itemsCount < 1){
            
        }else if(itemsCount === 1){
            return(
                <div
                    style={{
                        width:'50px',
                        height:'50px'
                    }}>
                    <Button 
                        icon={menuItems[i].icon}
                        text
                        aria-label={menuItems[i].label}
                        // tooltip={menuItems[i].label}
                        onClick={menuItems[i].command}
                        style={{
                            color:'var(--primary-color-text)',
                            width:'50px',
                            height:'50px',
                            margin:'0',
                            padding:'0'
                        }}
                        pt={{
                            root:{
                                style:{
                                    display:'flex',
                                    boxShadow:'none'
                                }
                            },
                            icon:{
                                style:{
                                    fontSize:'1.4em'
                                }
                            }
                        }}
                    />
                </div>
            )
        }else{
            const items = menuItems.filter(item => item.smMenu === i);
            const incon = () =>{
                switch(i){
                    case 0:
                    return "pi pi-desktop";
                    case 1:
                    return "pi pi-comments";
                    case 2:
                    return "pi pi-user";
                    case 3:
                    return "pi pi-bookmark";
                    case 4:
                    return "pi pi-envelope";
                    case 5:
                    return "pi pi-bars";
                    default:
                    return "pi pi-bars";
                }
            }

            return(
                <div style={{
                    width:'50px',
                    height:'50px'
                }}>
                    <SpeedDial
                        model={items}
                        direction="up"
                        transitionDelay={30}
                        showIcon={incon()}
                        hideIcon="pi pi-angle-double-down"
                        type='linear'
                        pt={{
                            root:{
                                style:{
                                    width:'50px',
                                    height:'50px',
                                }
                            },
                            actionIcon:{
                                style:{
                                    fontSize:'1.4em',
                                },
                            },
                            button:{
                                pt:{
                                    root:{
                                        className:'speeddial-button-sm',
                                        style:{
                                            boxShadow:'none',
                                            backgroundColor:'transparent',
                                            border:'none',
                                            color:'var(--primary-color-text)',
                                            paddingBottom:'25px'
                                        },
                                    }
                                },
                            },
                            menu:{
                                style:{
                                    padding:'0px',
                                    backgroundColor:'transit'
                                }
                            }
                        }}
                    />
                </div>
            )
        }

    }
    // 3001:管理者 3002:一般 3003:一般有料 3004:未アクティベート
    const menuContents =() =>{
        if(window.innerWidth < 768){
            //スマホ用
            return(
                <div className="" id="bottom-menu"  
                style={{ 
                    width:'100%', 
                    display: 'flex', 
                    justifyContent:'space-around',
                    height: '50px',
                    borderTop: '1px solid',
                    borderColor:'var(--primary-color-text)'
                }}>
                    {menuItemForSmatphone(0)}
                    {menuItemForSmatphone(1)}
                    {menuItemForSmatphone(2)}
                    {menuItemForSmatphone(3)}
                    {menuItemForSmatphone(4)}
                    {menuItemForSmatphone(5)}
                </div>
            )
        }else{
            //ブラウザ & タブレット
            return(
                <div className="layout-wrapper" id="leftside-menu"  style={{ width:'60px', display: 'block', height: '100%',
                backgroundColor:'val(--primary-500)' }}>
                    <ScrollPanel style={{ width: '100%', height: 'calc(100% - 150px)', overflowY:'auto',overflowX:'hidden' }}>
                        {mainMenuContents}
                    </ScrollPanel>
                    <ScrollPanel style={{ width: '100%', height: '150px', overflowY:'auto' }}>
                        {systemMenuContents}
                    </ScrollPanel>
                </div>
            )
        }
    }

    return (
        <>
        {menuContents()}
        </>
    )
}

export default Menu;