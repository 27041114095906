// SetupLogout.js
import React from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
import { ScrollPanel } from 'primereact/scrollpanel';

const SetupLogout = ({move}) => {
    const { t } = useTranslation();
    const LogoutAction =() =>{
        localStorage.removeItem('token');
        window.location.reload();
    }

    const logoutContent = (
        <>
            <div style={{ textAlign: 'center' }}>
                        <p>{t('areYouSureYouWantToLogout')}</p>

                        <p></p>
                        <Button label={t('logout')}  className="p-button-primary" onClick={LogoutAction} style={{ marginTop: '30px' }} />
                    </div>
        </>
    )
    const titleContent = (title)=>{
        return(
             <span style={{marginLeft:'10px',fontWeight:'bold'}}>{title}</span>
        )
     }

    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() => move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }
    
    return (
        <div style={{ alignItems: 'self-start', justifyContent: 'center', height: '100%', textAlign: 'center' }}>
            <div>
                {/* タイトルセクション */}
                <Toolbar start={backButtonContent()} center={titleContent(t('logout'))}
                    style={{ width: 'auto', height: '60px', 
                    paddingTop:'10px',
                    backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                />
            </div>
            <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'60px',
                                        backgroundColor: 'var(--primary-900)' }}>
                <div style={{ 
                    display:'flex', 
                    flexDirection:'column', 
                    textAlign:'left', 
                    gap:'20px',
                    padding:'20px'
                    }} >
                <Fieldset style={{textAlign:'left',margin:'20px', backgroundColor:'var(--primary-700)', color:'var(--primary-color-text)' }}>
                    <div className="m-0">
                        {logoutContent} 
                    </div>
                </Fieldset>
                </div>
            </ScrollPanel>
        </div>
    );
};

export default SetupLogout;