import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import SetSessionStrage from './compornet/SetSessionStrage';
import LoginPage from './compornet/LoginPage';
import RegisterPage from './compornet/RegisterPage';
import Dashboard from './compornet/Dashboard';
import Thanks from './compornet/Thanks';
import Activation from './compornet/Activation';
import ReActivation from './compornet/ReActivation';
import ChatBoard from './compornet/ChatBoard';
import AssistantsBoard from './compornet/AssistantsBoard';
import AssistantEditorBoard from './compornet/AssistantEditorBoard';
import BookmarkBoard from './compornet/BookmarkBoard';
import BriefingBoard from './compornet/BriefingBoard';
import ShopBoard from './compornet/ShopBoard';
import WalletBoard from './compornet/WalletBoard';
import MailBoard from './compornet/MailBoard';
import InstagramBoard from './compornet/InstagramBoard';
import FacebookBoard from './compornet/FacebookBoard';
import TelegramBoard from './compornet/TelegramBoard';
import XBoard from './compornet/Xboard';
import YoutubeBoard from './compornet/YoutubeBoard';
import QrcodeBoard from './compornet/QrcodeBoard';
import FlashBoard from './compornet/FlashBoard';
import CubeBoard from './compornet/CubeBoard';
import HelpBoard from './compornet/HelpBoard';
import SetupBoard from './compornet/SetupBoard';
import Menu from './compornet/Menu';
import TermsAndPoricy from './compornet/TermsAndPoricy';
import ConfigMonitoring from './compornet/ConfigMonitoring';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';

export const StateSystemChange = createContext()
export const StateAssistantChange = createContext()


function App() {
  const [token, setToken] = useState(null);
  const [systemChange, setSystemChange] = useState(true);
  const systemChangeData = { systemChange, setSystemChange };
  const [ assistantChange, setAssistantChange ] = useState(true);
  const assistantChangeData = { assistantChange, setAssistantChange };
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ elementHeight, setElementHeight ] = useState(0);
  const [ buttomPosition, setButtomPosition ] = useState(0);
  /* eslint-disable */
  const [ selectLanguage, setSelectLanguage ] = useState();

  const value = { locale: 'en' }
  useEffect(() => {
    const calculateElementHeight = () => {
      // ビューポートの高さを取得　大きい
      const viewportHeight = window.innerHeight;
      // ビューポートの高さを取得　小さい固定
      const viewportInnerHeight = document.documentElement.clientHeight
      setButtomPosition(viewportInnerHeight-viewportHeight);
      // 利用可能な高さを計算　
      const availableHeight = viewportHeight;
      // 要素に計算された高さを適用
      setElementHeight(availableHeight);
    };
    // コンポーネントがマウントされた時に高さを計算
    calculateElementHeight();
    // ウィンドウのリサイズ時に再計算
    window.addEventListener('resize', calculateElementHeight);
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', calculateElementHeight);
      document.body.style.height = calculateElementHeight;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setToken(localStorage.getItem('token'));
      SetSessionStrage();
      if (token !== null && token !== undefined) {
        await new Promise((resolve) => {
          // setTimeout(() => {
            resolve(SetSessionStrage());
          // }, 1000);
        });
        setIsAuthenticated(true);
      }
    };
    fetchData();
  }, [token]);

  const loginContent = () => {
    return (
      <PrimeReactProvider value={value}>
        <Router>
          <Routes>
            <>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/thanks" element={<Thanks />} />
              <Route path="/activation" element={<Activation />} />
              <Route path="/reactivation" element={<ReActivation />} />
              <Route path="/terms" element={<TermsAndPoricy purpose='terms' patern='none'/>} />
              <Route path="/privacy" element={<TermsAndPoricy purpose='privacy' patern='none'/>} />
              <Route path="*" element={<Navigate replace to="/login" />} />
            </>
          </Routes>
        </Router>
      </PrimeReactProvider>
    )
  }
  const smartPhoneSwitch = () =>{
    if(window.innerWidth < 768){
      return(
        <div 
          className="left-section" 
          style={{
            position:'absolute',
            zIndex:'9999999',
            width: '100%', 
            bottom: buttomPosition,
            left: 0,
            right: 0,
            height: '50px',
            xIndex: '1000',
            textAlign: 'center',
            backgroundColor:'var(--primary-900)', 
            color:'var(--primary-100)'
          }}
        > 
          {/* 左セクション（幅40px） */}
          <Menu />
        </div>
      )
    }else{
      return(
        <div 
          className="left-section" 
          style={{
            width: '60px', 
            textAlign: 'center',
            backgroundColor:'var(--primary-900)', 
            color:'var(--primary-100)'
          }}
        > 
          {/* 左セクション（幅40px） */}
          <Menu />
        </div>
      )
    }
  }
  const standardContent = () => {
    return (
      <PrimeReactProvider value={value}>
        <StateSystemChange.Provider value={systemChangeData}>
          <StateAssistantChange.Provider value={assistantChangeData}>
            <Router>
              <ConfigMonitoring />
              <div className="ai-element" style={{ height: `${elementHeight}px` }}>
                <div className="layout-wrapper" style={{ display: 'flex', height: `${elementHeight}px` }}>
                  {smartPhoneSwitch()}
                  <Routes>
                    <>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/chat" element={<ChatBoard />} />
                      <Route path="/assistants" element={<AssistantsBoard />} />
                      <Route path="/assistanteditor" element={<AssistantEditorBoard />} />
                      <Route path="/bookmark" element={<BookmarkBoard />} />
                      <Route path="/briefing" element={<BriefingBoard />} />
                      <Route path="/shop" element={<ShopBoard />} />
                      <Route path="/wallet" element={<WalletBoard />} />
                      <Route path="/mail" element={<MailBoard />} />
                      <Route path="/instagram" element={<InstagramBoard />} />
                      <Route path="/facebook" element={<FacebookBoard />} />
                      <Route path="/telegram" element={<TelegramBoard />} />
                      <Route path="/x" element={<XBoard />} />
                      <Route path="/youtube" element={<YoutubeBoard />} />
                      <Route path="/qrcode" element={<QrcodeBoard />} />
                      <Route path="/flash" element={<FlashBoard />} />
                      <Route path="/cube" element={<CubeBoard />} />
                      <Route path="/help" element={<HelpBoard />} />
                      <Route path="/setup" element={<SetupBoard />} />
                      <Route path="*" element={<Navigate replace to="/dashboard" />} />
                    </>
                  </Routes>
                </div>
              </div>
            </Router>
          </StateAssistantChange.Provider>
        </StateSystemChange.Provider>
      </PrimeReactProvider>
    )
  }

  if (isAuthenticated) {
    return (
      <>
        {standardContent()}
      </>
    )
  } else {
    return loginContent();
  }
}

export default App;
