import React, { useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useTranslation } from 'react-i18next';
import { PanelMenu } from 'primereact/panelmenu';

const HelpListMenu = ({onChange}) => {
    const { t } = useTranslation();
    const [expandedKeys, setExpandedKeys] = useState({'0':false,'1':false,'2':false});

    // const changeAssistant = (assistant) =>{
    //     onChange(assistant)
    // }
    // 仮ユーザーデーター
    const items = [
        {
            key: '0',
            label: t('assistantEditorManual'),
            icon: 'pi pi-list',
            command: () => {
                // changeAssistant('top')
            }
        },
        {
            key: '1',
            label: t('aiChatHelp'),
            icon: 'pi pi-comments',
            command: () => {
                // changeAssistant('new')
            }
        },
    ]

    // const changeMenu = (item) =>{
    //     onChange(item)
    // }
    const helpList = () => {
        return(
            <div style={{ height: '100%' }}>
                <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto'}}>
                        <PanelMenu model={items} className="w-full md:w-15rem"
                        expandedKeys={expandedKeys} 
                        onExpandedKeysChange={setExpandedKeys}
                        style={{width:'100%',padding:'5px'}}/>
                </ScrollPanel>
            </div>
        )
    }

return (
    <div
    style={{
        width: '100%', 
        height:'100%', 
    }}>
        {helpList()}
    </div>
)
}
export default HelpListMenu;