// ConfigMonitoring.js
import { useCallback, useContext, useEffect } from 'react';
import { StateSystemChange } from '../App';
import { PrimeReactContext } from 'primereact/api';
import { useTranslation } from 'react-i18next';

const ConfigMonitoring = () => {
    const { systemChange, setSystemChange } = useContext(StateSystemChange);
    const { changeTheme } = useContext(PrimeReactContext);
    const { i18n } = useTranslation();

    // THEME初期設定
    const changeThemeFuncion = useCallback(() => {
        const linkElement = document.getElementById('theme-link');
        const href = linkElement.getAttribute('href');
        const theme = sessionStorage.getItem('AISTheme');
        const darkmode = sessionStorage.getItem('darkmode');
        // 初期設定
        if(!theme)sessionStorage.setItem('AISTheme','laraBlue');
        if(!darkmode)sessionStorage.setItem('dark','light');

        //theme反映
         //DARKMODE
        if (darkmode === 'dark') {
            switch (theme) {
                case 'laraAmber':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKAMBER, 'theme-link');
                    break;
                case 'laraBlue':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKBLUE, 'theme-link');
                    break;
                case 'laraCyan':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKCYAN, 'theme-link');
                    break;
                case 'laraGreen':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKGREEN, 'theme-link');
                    break;
                case 'laraIndigo':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKINDIGO, 'theme-link');
                    break;
                case 'laraPink':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKPINK, 'theme-link');
                    break;
                case 'laraPurple':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKPURPLE, 'theme-link');
                    break;
                case 'laraTeal':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKTEAL, 'theme-link');
                    break;
                case 'bootstrap4Blue':
                    changeTheme( href, process.env.REACT_APP_API_THEME_BOOTSTRAP4DARKBLUE, 'theme-link');
                    break;
                case 'bootstrap4Purple':
                    changeTheme( href, process.env.REACT_APP_API_THEME_BOOTSTRAP4DARKPURPLE, 'theme-link');
                    break;
                case 'mdDeeppurple':
                    changeTheme( href, process.env.REACT_APP_API_THEME_MDDARKDEEPPURPLE, 'theme-link');
                    break;
                case 'mdIndigo':
                    changeTheme( href, process.env.REACT_APP_API_THEME_MDDARKINDIGO, 'theme-link');
                    break;
                case 'mdcDeeppurple':
                    changeTheme( href, process.env.REACT_APP_API_THEME_MDCDARKDEEPPURPLE, 'theme-link');
                    break;
                case 'mdcIndigo':
                    changeTheme( href, process.env.REACT_APP_API_THEME_MDCDARKINDIGO, 'theme-link');
                    break;
                default:
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARADARKBLUE, 'theme-link');
                    break;
            }
        } else {
            //LIGHT MODE
            switch (theme) {
                case 'laraAmber':
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTAMBER, 'theme-link');
                    break;
                    case 'laraBlue':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTBLUE, 'theme-link');
                        break;
                    case 'laraCyan':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTCYAN, 'theme-link');
                        break;
                    case 'laraGreen':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTGREEN, 'theme-link');
                        break;
                    case 'laraIndigo':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTINDIGO, 'theme-link');
                        break;
                    case 'laraPink':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTPINK, 'theme-link');
                        break;
                    case 'laraPurple':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTPURPLE, 'theme-link');
                        break;
                    case 'laraTeal':
                        changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTTEAL, 'theme-link');
                        break;
                    case 'bootstrap4Blue':
                        changeTheme( href, process.env.REACT_APP_API_THEME_BOOTSTRAP4LIGHTBLUE, 'theme-link');
                        break;
                    case 'bootstrap4Purple':
                        changeTheme( href, process.env.REACT_APP_API_THEME_BOOTSTRAP4LIGHTPURPLE, 'theme-link');
                        break;
                    case 'mdDeeppurple':
                        changeTheme( href, process.env.REACT_APP_API_THEME_MDLIGHTDEEPPURPLE, 'theme-link');
                        break;
                    case 'mdIndigo':
                        changeTheme( href, process.env.REACT_APP_API_THEME_MDLIGHTINDIGO, 'theme-link');
                        break;
                    case 'mdcDeeppurple':
                        changeTheme( href, process.env.REACT_APP_API_THEME_MDCLIGHTDEEPPURPLE, 'theme-link');
                        break;
                    case 'mdcIndigo':
                        changeTheme( href, process.env.REACT_APP_API_THEME_MDCLIGHTINDIGO, 'theme-link');
                        break;
                default:
                    changeTheme( href, process.env.REACT_APP_API_THEME_LARALIGHTBLUE, 'theme-link');
                    break;
            }
        }
        // const linkElements = document.querySelectorAll('#theme-link');
        // if(flug==1){
            // for (let i = 1; i < linkElements.length; i++) {
            //     // 最初の要素以外は削除
            //     linkElements[i].parentNode.removeChild(linkElements[i]);
            //   }
        // }else{
            // for (let i = 1; i < linkElements.length; i++) {
            //     // 最初の要素以外は削除
            //     linkElements[i].parentNode.removeChild(linkElements[i]);
            //   }
        // }
    },[changeTheme]);
    // Language初期設定
    // en: 'English',
    // ja: '日本語',
    // zhCn: '中国語'
    const changeLanguageFunction = useCallback(() => {
        const lng = sessionStorage.getItem('language');
        if (i18n && i18n.changeLanguage) { // changeLanguage関数が存在するか確認
            i18n.changeLanguage(lng);
        } else {
            console.error("i18n.changeLanguage関数は使用できません");
        }
    },[i18n]);
    useEffect(() => {
        if (systemChange) {
          const handleStorageChange = () => {
            changeThemeFuncion();
            changeLanguageFunction();
            // App.jsへ設定終了を送る
            setSystemChange(false);
          };
          handleStorageChange();
        }
      }, [systemChange,changeThemeFuncion,changeLanguageFunction,setSystemChange]);

    useEffect(() => {
        // 初回に一回だけ稼働
          const handleStorageChange = () => {
            // changeThemeFuncion(1);
            changeLanguageFunction();
            // App.jsへ設定終了を送る
            setSystemChange(false);
          };
          handleStorageChange();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return
};
export default ConfigMonitoring;