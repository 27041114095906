import React, { useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useTranslation } from 'react-i18next';
import { PanelMenu } from 'primereact/panelmenu';

const SetupListMenu = ({onChange}) => {
    const { t } = useTranslation();
    const [expandedKeys, setExpandedKeys] = useState({'0':true,'1':false,'2':false,'3':true});

    // 仮ユーザーデーター
    const items = [
        {
            key: '0',
            label: t('setUp'),
            icon: 'pi pi-cog',
               items:[
                {
                    key: '0_1',
                    label: t('systemSetting'),
                    icon: 'pi pi-wrench',
                    name: 'userinfomationedit',
                    command: ()=>{changeMenu('system')}
                }
            ]
        },
        {
            key: '1',
            label: t('logout'),
            icon: 'pi pi-sign-out',
            command: ()=>{changeMenu('logout')}
        },
        {
            key: '2',
            label: t('editUserInfomation'),
            icon: 'pi pi-user',
            name: 'userinfomationedit',
            command: ()=>{changeMenu('user')}
        },
        {
            key: '3',
            label: t('systemInfomation'),
            icon: 'pi pi-server',
                items:[
                    {
                        key: '3_1',
                        label: t('privacyPolicy'),
                        icon: 'pi pi-file',
                        name: 'privacyPolicy',
                        command: ()=>{changeMenu('privacyPolicy')}
                    },
                    {
                        key: '3_2',
                        label: t('term'),
                        icon: 'pi pi-file',
                        name: 'term',
                        command: ()=>{changeMenu('term')}
                    }
                ]
        }
    ]

    const changeMenu = (item) =>{
        onChange(item)
    }
    const setupdList = () => {
        return(
            <div style={{ height: '100%' }}>
                <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'60px'}}>
                    <div>
                        <PanelMenu 
                            pt={{
                                menuContent:{
                                    style:{
                                        backgroundColor:'var(--primary-500)',
                                        color:'var(--primary-color-text)'
                                    }},
                                label:{
                                    style:{
                                        color:'var(--primary-color-text)'
                                    }},
                                icon:{
                                    style:{
                                        color:'var(--primary-color-text)'
                                    }},
                                headerAction: () => ({
                                     style:{
                                        backgroundColor:'var(--primary-500)',
                                        color:'var(--primary-color-text)'
                                     }
                                    }),
                                action: () => ({
                                        style:{
                                        backgroundColor:'var(--primary-500)',
                                        color:'var(--primary-color-text)'
                                        }
                                    })
                            }}
                            model={items} 
                            className="w-full md:w-15rem"
                            expandedKeys={expandedKeys} 
                            onExpandedKeysChange={setExpandedKeys}
                            style={{
                                    width:'100%',padding:'5px',
                                }}
                        />
                    </div>
                </ScrollPanel>
            </div>
        )
    }

return (
    <div
    style={{
        width: '100%', 
        height:'100%', 
    }}>
        {setupdList()}
    </div>
)
}
export default SetupListMenu;