// RegisterPage.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const ReActivation = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const apiResendActivation = `${process.env.REACT_APP_API_USERS}/resendactivation`;
    const apiMailCheck = `${process.env.REACT_APP_API_USERS}/mailcheck`;
    const toast = useRef(null);
    const navigateToLogin = () => {
        navigate('/login');
    };
    const navigateToThanks= () => {
        navigate('/thanks');
    };

    const sendMessage = async () => { 
        const data = { email};
        // console.log(data);
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (url === apiMailCheck && response.status !== 200) {
                toast.current.show({severity:'warn', summary: 'Warning', detail:email+t('notRegistered'), life: 3000});
                throw new Error('email is not registered');
            }
            if (url === apiResendActivation && response.status !== 200) {
                toast.current.show({severity:'warn', summary: 'Warning', detail:t('activationEmailCouldNotBeSent'), life: 3000});
                throw new Error('Activation email could not be sent');
            }
            // メールアドレスチェックでエラーがなければ、登録処理を実行
            if (url === apiMailCheck && response.status === 200) {
                await sendRequest(apiResendActivation);
            }
            if(url === apiResendActivation && response.status === 200){
                toast.current.show({severity:'success', summary: 'Success', detail:email+t('anActivationEmailHasBeenSent'), life: 10000});
                navigateToThanks(); // 登録後にログインページへ遷移
            }
        };
        try {
            await sendRequest(apiMailCheck);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validate: (data) => {
            let errors = {};
            setEmail(data.email);
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendMessage();
            formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    return (
        <div className="Register-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                <Card title={t('reactivation')} style={{ width: '300px' }}>
                    <div className="p-fluid">
                        <div className="p-fluid">
                            <InputText 
                                inputid="email" 
                                name="email" 
                                value={email}
                                 placeholder={t('email')}
                                 keyfilter="email" 
                                 onChange={(e) => {
                                    formik.setFieldValue('email', e.target.value);
                                }} 
                                 autoComplete="email" />
                            {getFormErrorMessage('email')}
                        </div>
                        <Button label={t('reactivation')} type="submit" />
                        <Button label={t('login')} type="button" className="p-button-secondary" onClick={navigateToLogin} style={{ marginTop: '10px' }} />
                    </div>
                </Card>
            </form>
        </div>
    );
};

export default ReActivation;