import React, { useState, useEffect } from 'react';
import { StateAssistantChange } from '../App';
import { useTranslation } from 'react-i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { PanelMenu } from 'primereact/panelmenu';
import { Avatar } from 'primereact/avatar';

const AssistantsList = ({onChange}) => {
    const { t } = useTranslation();
    const [ assistantChange, setAssistantChange ] = useState(StateAssistantChange);
    const [expandedKeys, setExpandedKeys] = useState({'1':true,'2':true,'3':true,'4':true});
    const [ assistantsSystemList, setAssistantsSystemList ] = useState([]);
    const [ assistantsFriendsList, setAssistantsFriendsList ] = useState([]);
    const [ assistantsSelfList, setAssistantsSelfList ] = useState([]);
    const [ assistantsPublicList, setAssistantsPublicList ] = useState([]);
    // const [ friendsList, setFriendsSelfList ] = useState([]);

    const searchContent = (
        <div className="p-input-icon-left" 
        style={{
            width:'100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin:'5px',
            backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
            }}>
            <i className="pi pi-search" style={{color:'var(--primary-color-text)'}}/>
            <InputText placeholder={t('search')} 
                style={{width:'100%',
                    backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
                }}/>
        </div>
    )

    const assistantSearchBar = () =>{
        return(
            <div className="card p-fluid" 
            style={{ 
                width: 'auto', 
                height:'60px', 
                margin:'0px', 
                backgroundColor: 'var(--primary-500)',
                color:'var(--primary-color-text)',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                {searchContent}
            </div>
        )
    };

    const itemRenderer = (item) => (
        <div className='p-menuitem-content' style={{ backgroundColor:'var(--primary-500)'}}>
            <div className="flex align-items-center p-menuitem-link" onClick={()=>changeAssistant(item.assistant_id)} 
            style={{color:'var(--primary-color-text)'}}>
                {item.avatar ? (
                    <Avatar image={item.avatar} size="normal" shape="circle" style={{minWidth:'45px',minHeight:'45px'}}/>
                ):(
                    <Avatar icon="pi pi-user" size="normal" shape="circle" style={{minWidth:'45px',minHeight:'45px'}} />
                )}
                <div style={{marginLeft:'10px'}}>{item.name}</div>
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </div>
        </div>
    );

    // 仮ユーザーデーター
    const items = [
        {
            key: '1',
            label: t('createdAssistants'),
            items: assistantsSelfList.map((assistant, index) => ({
                key: `1_${index+1}`,
                name: assistant.name,
                assistant_id: assistant.assistant_id,
                avatar: `uploads/${assistant.avatar}`,
                template: itemRenderer,
            })),
        },
        {
            key: '2',
            label: t('shareSystem'),
            items: assistantsSystemList.map((assistant, index) => ({
                key: `2_${index+1}`,
                name: assistant.name,
                assistant_id: assistant.assistant_id,
                avatar: `uploads/${assistant.avatar}`,
                template: itemRenderer,
            })),
        },
        {
            key: '3',
            label: t('shareFriendsSystem'),
            items: assistantsFriendsList.map((assistant, index) => ({
                key: `3_${index+1}`,
                name: assistant.name,
                assistant_id: assistant.assistant_id,
                avatar: `uploads/${assistant.avatar}`,
                template: itemRenderer,
            })),
        },
        {
            key: '4',
            label: t('sharePublicSystem'),
            items: assistantsPublicList.map((assistant, index) => ({
                key: `4_${index+1}`,
                name: assistant.name,
                assistant_id: assistant.assistant_id,
                avatar: `uploads/${assistant.avatar}`,
                template: itemRenderer,
            })),
        }
    ]

    const changeAssistant = (assistant) =>{
            onChange(assistant)
    }

    const menuItemList = () => {
        return(
            <div style={{ height: '100%' }}>
            <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto', paddingBottom:'60px' }}>
                <PanelMenu
                    pt={{
                        menuContent:{
                            style:{
                                backgroundColor:'var(--primary-500)',color:'var(--primary-color-text)'
                            }},
                        headerAction: () => ({
                            style:{
                                backgroundColor:'var(--primary-500)',
                                color:'var(--primary-color-text)'
                            }
                            })
                    }}
                    model={items} className="w-full md:w-15rem"
                    expandedKeys={expandedKeys} 
                    onExpandedKeysChange={setExpandedKeys}
                    style={{width:'100%',padding:'5px'}}/>
            </ScrollPanel>
            </div>
        )
    }

    const getAssitantList = async() =>{
        const token = localStorage.getItem('token');
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const list = await response.json();
                switch(data.searchflug){
                    case 'self': setAssistantsSelfList(list);
                    break;
                    case 'system': setAssistantsSystemList(list);
                    break;
                    case 'friends': setAssistantsFriendsList(list);
                    break;
                    case 'public': setAssistantsPublicList(list);
                    break;
                    default:;
                }
                // console.log(list);
                }
            };
        try {
            const url = `${process.env.REACT_APP_API_ASSISTANTS}/list`;
            let searchflug = 'self';
            let data = { token, searchflug };
            await sendRequest(url,data);
            searchflug = 'system';
            data = { token, searchflug };
            await sendRequest(url,data);
            searchflug = 'friends';
            data = { token, searchflug };
            await sendRequest(url,data);
            searchflug = 'public';
            data = { token, searchflug };
            await sendRequest(url,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if(assistantChange)getAssitantList();
        setAssistantChange(false);
    },[assistantChange]);

    useEffect(()=>{
        getAssitantList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])// このuseEffectはコンポーネントのマウント時にのみ実行されます

    return (
        <div
        style={{
            width: '100%', 
            height:'100%', 
        }}>
        {assistantSearchBar()}
        {menuItemList()}
        </div>
    )
}
export default AssistantsList;