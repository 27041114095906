import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { StateAssistantChange } from '../App';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataScroller } from 'primereact/datascroller';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import AceEditor from "react-ace";
import ReactList from 'react-list';
// import fileSaver from 'file-saver';
import { FilePond, registerPlugin } from 'react-filepond';
import { useReactMediaRecorder } from "react-media-recorder-2";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-terminal";

import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-dart";
import "ace-builds/src-noconflict/mode-dockerfile";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-jsp";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/mode-kotlin";
import "ace-builds/src-noconflict/mode-makefile";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-nginx";
import "ace-builds/src-noconflict/mode-objectivec";
import "ace-builds/src-noconflict/mode-pascal";
import "ace-builds/src-noconflict/mode-perl";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/mode-php_laravel_blade";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-svg";
import "ace-builds/src-noconflict/mode-swift";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-vbscript";
import "ace-builds/src-noconflict/mode-vue";
import "ace-builds/src-noconflict/mode-xml";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


export default function Chat( selectedThread ) {
    // const { transcript, listening, startListening, stopListening } = useSpeechRecognition();
    const move = selectedThread.move;
    const { t } = useTranslation();
    const toast = useRef(null);
    const pondRef = useRef(null);
    const chatVoiceRef = useRef(null);
    const chatInputRef = useRef(null);
    const toastOnChat = useRef(null);
    const copyRef = React.createRef();
    const chatScrollPanelRef = useRef(null);
    const chatInputProgressRef = useRef(null);
    const voiceRef = useRef(null);
    const token = localStorage.getItem('token');
    const username = sessionStorage.getItem('username');
    const { setAssistantChange } = useContext(StateAssistantChange);
    const [ files, setFiles] = useState();
    const [ input, setInput ] = useState('');
    /* eslint-disable */
    const [ copied, setCopied] = useState(false);
    const [ mediaPermission, setMediaPermission ] = useState(false);
    const [ fontSize, setFontSize ] = useState(4);
    const [ messages, setMessages ] = useState([]);
    const [ filePondAF, setFilePondAF ] = useState('');
    const [ showVoice, setShowVoice ] = useState(false);
    const [ uploadFiles, setUploadFiles ] = useState([]);
    const [ showSpinner, setShowSpinner ] = useState(false);
    const [ inputareaHeight, setInputareaHeight ] = useState(4);
    const [ leftWindowHeight, setLeftWindowHeight ] = useState(0);
    const [ inputAreaHeightSize, setInputAreaHeightSize ] = useState(0);
    const [ filePondSelectFiles, setFilePondSelectFiles ] = useState([]);
    const [ selectedMessages, setSelectedMessages ] = useState([]);
    const [ voice, setVoice] = useState('');
    const [ audioType, setAudioType ] = useState([]);
    const [ messageVoice, setMessageVoice ] = useState(false);
    const [ speechFile, setSpeechFile ] = useState('');
    const voiceData = [];
    // const [ voiceText, setVoiceText ] = useState('');
    // const [ voiceModel, setVoiceModel ] = useState('');
    /* eslint-disable no-unused-vars */
    const uploadFilesMax = 5;
    const maxFileSize = '5MB';
    const allowMultiple = true;
    const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = 
        useReactMediaRecorder({
            audio: true,
            video: false,
            echoCancellation: true
         });


    // 要素への参照を作成
    const elementRef = useRef(null);
    // const titleBarRef = useRef(null);
    const topPanelRef = useRef(null);
    const botomPanelRef = useRef(null);
    const buttonAreaRef = useRef(null);

    const bottomPanelSizing = useCallback(() => {
        // 関数の定義
        if (buttonAreaRef.current && botomPanelRef.current) {
            setInputareaHeight(Math.floor((botomPanelRef.current.offsetHeight - buttonAreaRef.current.offsetHeight) / (fontSize * 1.25) - 1));
        }
    }, [buttonAreaRef, botomPanelRef, fontSize]); // 依存配列に関数が依存する変数を追加

    useEffect(() => {
        const calculateElementHeight = () => {
            // ビューポートの高さを取得
            const viewportHeight = window.innerHeight;
            // ブラウザの高さを取得
            const availableHeight = viewportHeight;
            // 要素に計算された高さを適用
            if(window.innerWidth < 768){
                setLeftWindowHeight(availableHeight - 60-50);
            }else{
                setLeftWindowHeight(availableHeight - 60);
            }
        };

        // コンポーネントがマウントされた時に高さを計算
        calculateElementHeight();
        // ウィンドウのリサイズ時に再計算
        window.addEventListener('resize', calculateElementHeight);
        if (elementRef.current) {
            const style = window.getComputedStyle(elementRef.current);
            if (parseInt(style.fontSize, 10) > 4) setFontSize(parseInt(style.fontSize, 10));
        }
        window.addEventListener('resize', bottomPanelSizing);

    }, [bottomPanelSizing]);


    const sendMessage = async (e) => {
        voiceData.voieModel='';
        voiceData.voiceTestText='';
        e.preventDefault();
        const assistant_id = selectedThread.selectedThread.assistant_id;
        const thread_id = selectedThread.selectedThread.thread_id;
        // ここにバックエンドとの通信ロジックを追加

        const sendRequest = async (url,data) => {
            const response = await fetch( url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responceData = await response.json();
            if(responceData && responceData.id){
                const id = responceData.id;
                const created_at = responceData.created_at;
                const text = responceData.content[0].text?.value ?? "";
                const file_ids = responceData.file_ids;
                // const user = selectedThread.selectedThread.name;
    
                setMessages(messages => [...messages, { 
                    id:id, 
                    created_at:created_at, 
                    text: text, 
                    sender:username, 
                    user: null, 
                    talker:'user',
                    file_ids:file_ids
                }]);
                // chatScrollPanelRef.current.scrollToIndex(messages.length)
                // inputフィールドを非アクティブ
                setShowSpinner(true)
                runThread();
            }
        }
        try {
            const data = { token, thread_id, input, assistant_id };
            // console.log(input);
            if(input.length > 0){
                await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/setthreadmessage`,data);
            }
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
        setInput(''); // Clear the input after sending
    };

    const sendMessageAndFiles = async (e) => {
        voiceData.voieModel='';
        voiceData.voiceTestText='';
        e.preventDefault();
        const assistant_id = selectedThread.selectedThread.assistant_id;
        const thread_id = selectedThread.selectedThread.thread_id;
        // ここにバックエンドとの通信ロジックを追加
        const sendRequest = async (url,formData) => {
            const response = await fetch( url, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responceData = await response.json();
            if(responceData && responceData.id){
                const id = responceData.id;
                const created_at = responceData.created_at;
                const text = responceData.content[0].text?.value ?? "";
                const file_ids = responceData.file_ids;
                // const user = selectedThread.selectedThread.name;
                if(file_ids.length > 0) {
                    for(const file_id of file_ids) {
                        await getFileInfo(file_id);
                    }
                }
    
                setMessages(messages => [...messages, { 
                    id:id, 
                    created_at:created_at, 
                    text: text, 
                    sender:username, 
                    user: null, 
                    talker:'user',
                    file_ids:file_ids
                }]);
                // chatScrollPanelRef.current.scrollToIndex(messages.length)
                // inputフィールドを非アクティブ
                setShowSpinner(true)
                runThread();
            }
        }
        try {
            const formData = new FormData();
            // ファイルを追加
            for (const file of uploadFiles) {
                // const fileObj = await file.FileSystemFileHandle.getFile();
                const fileObj = file.FileSystemFileHandle;
                // console.log(fileObj); // File オブジェクトの内容を確認
                formData.append('files', fileObj);
            }
            // フォームデータを追加
            formData.append('token', token);
            formData.append('thread_id', thread_id);
            formData.append('input', input);
            formData.append('assistant_id', assistant_id);
            // console.log(input);
            if(input.length > 0){
                await sendRequest(`${process.env.REACT_APP_API_FILES}/putinthread`,formData);
            }
        } catch (error) {
            console.error("Failed to send the message: ", error);
        }
        setUploadFiles([]);
        setInput(''); // Clear the input after sending
    };

    const sendImage = async (file) => {
        voiceData.voieModel='';
        voiceData.voiceTestText='';
        const assistant_id = selectedThread.selectedThread.assistant_id;
        const thread_id = selectedThread.selectedThread.thread_id;
        // ここにバックエンドとの通信ロジックを追加
        const sendRequest = async (url,formData) => {
            const response = await fetch( url, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responceData = await response.json();
            if(responceData && responceData.id){
                const id = responceData.id;
                const created_at = responceData.created_at;
                const text = responceData.content[0].text?.value ?? "";
                const file_ids = responceData.file_ids;
                // const user = selectedThread.selectedThread.name;
                if(file_ids.length > 0) {
                    for(const file_id of file_ids) {
                        await getFileInfo(file_id);
                    }
                }
    
                setMessages(messages => [...messages, { 
                    id:id, 
                    created_at:created_at, 
                    text: text, 
                    sender:username, 
                    user: null, 
                    talker:'user',
                    file_ids:file_ids
                }]);
                // chatScrollPanelRef.current.scrollToIndex(messages.length)
                // inputフィールドを非アクティブ
                setShowSpinner(true)
                runThread();
            }
        }
        try {
            const formData = new FormData();
            // ファイルを追加
            formData.append('files', file);
            // フォームデータを追加
            formData.append('token', token);
            formData.append('thread_id', thread_id);
            formData.append('assistant_id', assistant_id);
            // console.log(input);
            if(input.length > 0){
                await sendRequest(`${process.env.REACT_APP_API_FILES}/putcreateimageinthread`,formData);
            }
        } catch (error) {
            console.error("Failed to send the message: ", error);
        }
        setUploadFiles([]);
        setInput(''); // Clear the input after sending
    };

    const sendMessageAndVoice = async (voiceFile) => {
        voiceData.voieModel='';
        voiceData.voiceTestText='';
        const assistant_id = selectedThread.selectedThread.assistant_id;
        const thread_id = selectedThread.selectedThread.thread_id;
        // ここにバックエンドとの通信ロジックを追加
        const sendRequest = async (url,formData) => {
            const response = await fetch( url, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responceData = await response.json();
            if(responceData && responceData.id){
                const id = responceData.id;
                const created_at = responceData.created_at;
                const text = responceData.content[0].text?.value ?? "";
                const file_ids = responceData.file_ids;
                // const user = selectedThread.selectedThread.name;
                if(file_ids.length > 0) {
                    for(const file_id of file_ids) {
                        await getFileInfo(file_id);
                    }
                }
    
                setMessages(messages => [...messages, { 
                    id:id, 
                    created_at:created_at, 
                    text: text, 
                    sender:username, 
                    user: null, 
                    talker:'user',
                    file_ids:file_ids
                }]);
                // chatScrollPanelRef.current.scrollToIndex(messages.length)
                // inputフィールドを非アクティブ
                setShowSpinner(true)
                runThread();
            }
        }
        try {
            const formData = new FormData();
            // ファイルを追加
            // const fileObj = voiceFile;
            // const fileObj = new File([voiceFile], 'voice.wav', { type: 'audio/wav' });
            // console.log(voiceFile); // File オブジェクトの内容を確認
            formData.append('files', voiceFile);
            // フォームデータを追加
            formData.append('token', token);
            formData.append('thread_id', thread_id);
            formData.append('input', '');
            formData.append('assistant_id', assistant_id);
            // console.log(input);
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/voicePrompt`,formData);
        } catch (error) {
            console.error("Failed to send the message: ", error);
        }
        setUploadFiles([]);
        setInput(''); // Clear the input after sending
    };

    const deleteMessage = async(message_id) => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url,data) => {
            const response = await fetch( url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // const responceData = await response.json();
        }
        try {
            const data = { token, thread_id, message_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/deletethreadmessage`,data);
            
        } catch (error) {
            console.error("Failed to send the message: ", error);
        }
    }

    const threadGetMessage = async(msg_id) => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            const responceData = await response.json();
            // console.log('threadGetMessage:'+responceData.assistant_id);
            if(responceData.content) {
                // console.log(responceData.content)
                const user = selectedThread.selectedThread.name;
                const id=msg_id;
                const created_at=responceData.content.created_at;
                const text=responceData.content[0].text?.value?? "";
                const assistant_id = responceData.assistant_id;
                const file_ids = responceData.file_ids;
                const metadata = responceData.metadata;
                if(file_ids.length > 0) {
                    for(const file_id of file_ids) {
                     await getFileInfo(file_id);
                    }
                }
                setMessages(messages => [...messages, { 
                    id:id, 
                    created_at:created_at,
                    text: text, 
                    sender:user, 
                    user:assistant_id,
                    file_ids:file_ids,
                    talker:'ai',
                    metadata:metadata
                 }]);
                setShowSpinner(false);
                if(messageVoice){
                    voiceData.voiceText=text;
                    getAssitantAndSpeech(assistant_id);
                }
                //VoiceMessageがtrueなら音声を生成

            }

        };
        try {
            const data = { token, thread_id, msg_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/threadretrievemessage`,data);
        } catch (error) {
            setShowSpinner(false);
            console.error('Error:', error);
        }
    }


    const generateImage = async (prompt,size) => {

        const sendRequest = async (url,data) => {
            const response = await fetch( url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const responceData = await response.json();
            //responceData.reply[].url
            for(const rep of responceData.reply ){
                const url = rep.url;
                const reader = new FileReader();
                reader.onload = () =>{
                    const file = new File([reader.result], 'image.png');
                }
                reader.readAsDotoURL(url);
            }
            console.log(responceData);
            // if(responceData && responceData.id){
            //     const id = responceData.id;
            //     const created_at = responceData.created_at;
            //     const text = responceData.content[0].text?.value ?? "";
            //     const file_ids = responceData.file_ids;
            //     // const user = selectedThread.selectedThread.name;
    
            //     setMessages(messages => [...messages, { 
            //         id:id, 
            //         created_at:created_at, 
            //         text: text, 
            //         sender:username, 
            //         user: null, 
            //         talker:'user',
            //         file_ids:file_ids
            //     }]);
            //     // chatScrollPanelRef.current.scrollToIndex(messages.length)
            //     // inputフィールドを非アクティブ
            //     setShowSpinner(true)
            //     runThread();
            // }
        }
        try {
            // console.log('token:' + token);
            // console.log('prompt:' + prompt);
            // console.log('size:'+ size);
            const data = { token, prompt, size };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/generateimage`,data);
        } catch (error) {
            console.error("Failed to send the message: ", error);
        }
    };


    const getSpeech = async() =>{
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const speechFileName = await response.json();
                setSpeechFile(`${process.env.REACT_APP_DOWNLOADPATH}/files/createmodel/${speechFileName}`);
                // console.log(voiceFileName);
            }
        };
        try {
            const voiceModel = voiceData.voiceModel;
            const voiceText = voiceData.voiceText;
            const data = { token, voiceModel, voiceText };
            // console.log(token)
            // console.log(voiceModel)
            // console.log(voiceText)
            sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/speech`,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const functionChecker =(item) =>{
        // console.log(item);
        if(item.type === 'function'){
            //createImageはAPIで処理
            // if(item.function.name === 'createImage'){
            //     const argumentsData = JSON.parse(item.function.arguments);
            //     const prompt = argumentsData["prompt"];
            //     const size = argumentsData["size"];
            //     // console.log('arguments:'+ argumentsData);
            //     // console.log('prompt:'+ prompt);
            //     // console.log('size:'+ size);
            //     generateImage(prompt,size);
            // }
        }
    }

    const runStepCancel = async(run_id) => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            // console.log(response)
            const responceData = await response.json();
        };
        try {
            const data = { token, thread_id, run_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/threadmessageruncancel`,data);
        } catch (error) {
            setShowSpinner(false)
            console.error('Error:', error);
        }
    }
    const runStepList = async(run_id) => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const retry = 20;
        const sendRequest = async (url,data) => {
            for (let i = 0; i <= retry; i++) {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response)
                const responceData = await response.json();
                if(responceData.data.length > 0 ){
                    //返答メッセージあり
                    if(responceData.data[0].status === 'completed'){
                        const msg_id = responceData.data[0].step_details.message_creation.message_id;
                        // メッセージを取りにいく
                        threadGetMessage(msg_id);
                        break;
                    }else{
                        // console.log(responceData.data[0]);
                        if(responceData.data[0].type === 'tool_calls' && responceData.data[0].step_details.tool_calls.length > 0){
                            for(const item of responceData.data[0].step_details.tool_calls){
                                // console.log(item);
                                // runStepCancel(run_id);
                                functionChecker(item);
                            }
                            i = retry-2;
                            await new Promise((resolve) => setTimeout(resolve, 20000)); // 15秒間待機
                        }else{
                            // console.log('リピート2')
                            await new Promise((resolve) => setTimeout(resolve, 3000)); // 3秒間待機
                        }
                    }
                }else{
                    // console.log('リピート1')
                    await new Promise((resolve) => setTimeout(resolve, 3000)); // 3秒間待機
                }
                // 20回リトライしても完了しなかった場合
                if (i === retry) {
                    // エラー表示
                    toastOnChat.current.show({ severity: 'error', summary: "Error", detail: t('openAINetworkError'), life: 3000 });
                    setShowSpinner(false)
                }
            };
        };
        try {
            const data = { token, thread_id, run_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/threadrunsteplist`,data);
        } catch (error) {
            setShowSpinner(false)
            console.error('Error:', error);
        }
    }

    // const messageRetrieve = (run_id) => {
    //     const thread_id = selectedThread.selectedThread.thread_id;
    //     const sendRequest = async (url,data) => {
    //         const response = await fetch(url, {
    //             method: 'POST', // HTTPメソッド
    //             headers: {
    //                 'Content-Type': 'application/json', // コンテントタイプをJSONに指定
    //             },
    //             body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
    //         });
    //         const responceData = await response.json();
    //         console.log(responceData);
    //     };
    //     try {
    //         const data = { token, thread_id, run_id };
    //         sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/threadmessageretrieve`,data);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // }

    const runThread = async() => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const assistant_id = selectedThread.selectedThread.assistant_id;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            const responceData = await response.json();
            if(responceData && responceData.id){
                const run_id = responceData.id;
                runStepList(run_id);
            }
        };
        try {
            const data = { token, thread_id, assistant_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/threadmessagerun`,data);
        } catch (error) {
            setShowSpinner(false)
            console.error('Error:', error);
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            if(uploadFiles.length > 0){
                sendMessageAndFiles(e);
            }else{
                sendMessage(e);
            }
            setInput('');
        }
    };
    

    const getThreadData = async() =>{
        setMessages([])
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                //chatの更新
                // console.log(response);
                const data = await response.json();
                setMessages([]);
                for(let i = data.length-1; i >= 0; i--) {
                //  console.log(data[i])
                    const id = data[i].id;
                    const created_at = data[i].created_at;
                    const text = data[i]?.content?.[0]?.text?.value ?? "";
                    const user = selectedThread.selectedThread.name;
                    const file_ids = data[i].file_ids;
                    const metadata = data[i].metadata;

                    if(metadata.deleted !== 'true'){
                        if(file_ids.length > 0) {
                            for(const file_id of file_ids) {
                             await getFileInfo(file_id);
                            }
                        }
    
                        if(data[i].assistant_id){
                            const assistant_id = data[i].assistant_id;
                            setMessages(messages => [...messages, { 
                                id:id, 
                                created_at:created_at, 
                                text: text, 
                                sender:user, 
                                user:assistant_id, 
                                file_ids:file_ids,
                                talker:'ai',
                                metadata:metadata
                            }]);
                        }else{
                            setMessages(messages => [...messages, { 
                                id:id, 
                                created_at:created_at, 
                                text: text, 
                                sender:username, 
                                user: null, 
                                file_ids:file_ids,
                                talker:'user',
                                metadata:metadata
                            }]);
                        }
                    }
                }
            }
        };
        try {
            const data = { token, thread_id };
            await sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/getthreaddata`,data);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(()=>{
        if(messages.length>10){
            setTimeout(() => {
                chatScrollPanelRef.current.scrollTo(messageVisibleDataCount())
            },500);
            setTimeout(() => {
                chatScrollPanelRef.current.scrollTo(messageVisibleDataCount())
            },1500);
        } 
    },[messages]);

    useEffect(()=>{
        if(selectedThread.selectedThread && selectedThread.selectedThread.theread_id !== null){
            const pond = pondRef.current;
            if(pond){
                const pondElement = pond._element;
                pondElement.style.display = 'none';
                pond.removeFiles();
            }
            setSpeechFile('');
            voiceData.voiceModel = '';
            voiceData.voiceText = '';
            getThreadData();
            setShowSpinner(false);
            setUploadFiles([]);
            inputHeight();
            setFiles([]);
            setSelectedMessages([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedThread]);

    //テキスト送信した後スピナーを出し入力不可にする
    //受信後戻す
    useEffect(()=>{
        const inputRef = chatInputRef.current;
        if(inputRef != null){
            if( showSpinner ){
                inputRef.disabled = true;
            }else{
                inputRef.disabled = false;
            }
        }
    },[showSpinner]);


    const titleContent = (title)=>{
        let fontSize = '16px';
        if(window.innerWidth < 768){
            fontSize='12px';
            if(title.length > 20){
                fontSize='8px';
            }
        }
        const titleAvater =()=>{
            if (selectedThread.selectedThread) {
                return (
                    <Avatar image={selectedThread.selectedThread.avatar} shape="circle" />
                )
            }
        }
       return(
        <div style={{
            width:'100%',
            display:'flex',
            alignItems:'center',
        }}>
        {titleAvater()}
        <span style={{
            marginLeft:'10px',
            fontWeight:'bold',
            fontSize:fontSize,
            }}>{title}</span>
        </div>
       )
    }
    
    const accept = () => {
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'DELETE', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
            });
            if (response.status === 200) {
                //menuのリスト更新
                setAssistantChange(true);
                toast.current.show({ severity: 'info', summary: t('info'), detail: t('deletedChat'), life: 3000 });
                move(true);
            }
        };
        try {
            const urlToken = encodeURIComponent(token);
            const urlThread_id = encodeURIComponent(thread_id);
            const url = `${process.env.REACT_APP_API_THREDS}/${urlThread_id}?token=${urlToken}`;
            sendRequest(url);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const reject = () => {
    };

    const [visible, setVisible] = useState(false);
    const buttonEl = useRef(null);


    const endContent = ()=>{
        let delTitle = '';
        if(window.innerWidth > 768)delTitle=t('deleteChat');
        if (selectedThread.selectedThread.role === 'system') {
            return
        }else{
            return (
                <>
                    <Toast ref={toast} />
                    <ConfirmPopup target={buttonEl.current} visible={visible} onHide={() => setVisible(false)} 
                        message={t('doYouWantToDeleteThisChat')} icon="pi p-button-danger" accept={accept} reject={reject} />
                    <Button label={delTitle} 
                        ref={buttonEl} 
                        severity="secondary" 
                        outlined 
                        onClick={() => setVisible(true)}
                        icon="pi pi-trash"
                        size="small"
                        style={{
                            color:'var(--primary-color-text)',
                            backgroundColor:'var(--primary-800)',
                            border:'1px solid var(--primary-color-text)',
                            height:'40px'
                        }}
                        />
                </>
            )
        }
    };

    const fileUploadFunction = async(mode) =>{
        try{
            let files;
            let fileType='file';
            if(mode==='image'){
                fileType='image';
                files = await window.showOpenFilePicker({
                    types: [
                      {
                        description: 'Images',
                        accept: {
                          'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
                        },
                      },
                    ],
                    multiple: true,
                });
            }else{
                files = await window.showOpenFilePicker({
                    types: [
                        {
                            description: 'All Files',
                            accept: {
                            // Code Files
                            'text/x-c': ['.c'],
                            'text/x-csharp': ['.cs'],
                            'text/x-c++': ['.cpp'],
                            'text/x-java': ['.java'],
                            'text/x-python': ['.py'],
                            'text/x-ruby': ['.rb'],
                            'text/x-script.python': ['.py'],
                            'text/x-tex': ['.tex'],
                            'text/plain': ['.txt'],
                            'text/css': ['.css'],
                            'text/javascript': ['.js'],
                            'application/typescript': ['.ts'],
                            'application/xml': ['.xml'],
                            // Documents 
                            'application/csv': ['.csv'],
                            // 'application/msword': ['.doc'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                            'text/html': ['.html'],
                            'application/pdf': ['.pdf'],
                            'text/markdown': ['.md'],
                            //Other Files'
                            'application/json': ['.json'],
                            // 'application/x-sh': ['.sh'],
                            'application/x-tar': ['.tar'],
                            'application/zip': ['.zip'],
                            'text/x-php': ['.php'],
                            //Audio and Video
                            'audio/flac': ['.flac'],
                            'audio/mpeg': ['.mp3'],
                            'video/mp4': ['.mp4'],
                            'video/mpeg': ['.mpeg'],
                            'audio/mpeg': ['.mpga'],
                            'audio/mp4': ['.m4a'],
                            'audio/ogg': ['.ogg'],
                            'audio/wav': ['.wav'],
                            'video/webm': ['.webm']
                            },
                        },
                    ],
                    multiple: true,
                });
            }
    
            const oldFilesArray = uploadFiles.reduce((acc, file) => {
                acc.push(file);
                return acc;
                }, []);
    
            const filesArray = files.reduce((acc, file) => {
                const fileProp = file.getFile();
                acc.push(file);
                return acc;
              }, []);
    
            const newFiles = [];
            oldFilesArray.forEach((file) => {
                newFiles.push(file);
            });
            filesArray.forEach((file) => {
                newFiles.push({
                    FileSystemFileHandle:file,
                  type: fileType,
                });
            });
            setUploadFiles(newFiles);
        }catch(error) {

        }
    }

    useEffect(() => {
        // console.log(filePondSelectFiles.length)
        setUploadFiles([]);
        const newFiles = [];
        if(filePondSelectFiles.length > 0) {
            for(const item of filePondSelectFiles){
                // console.log(item)
                // console.log(item.file)
                if(item.file){
                    // console.log(item.file)
        
                    let type = 'file';
                    if (item.file.type.startsWith('image/'))type='image';
        
                    newFiles.push({
                        FileSystemFileHandle:item.file,
                        type:type
                    })
                }
            }
            setUploadFiles(newFiles);
        }
        // console.log(filePondSelectFiles[0])
    }, [filePondSelectFiles]);

    const getAssitantAndSpeech = async(assistant_id) =>{
        if(assistant_id){
            const sendRequest = async (url,data) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                });
                if (response.status === 200) {
                    const list = await response.json();
                    voiceData.voiceModel = list[0].voiceModel;
                    getSpeech();
                }
            };
            try {
                const data = { assistant_id };
                sendRequest(`${process.env.REACT_APP_API_ASSISTANTS}/assistant`,data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    // const sendTest = async() =>{
    //     const sendRequest = async (url,data) => {
    //         const response = await fetch(url, {
    //             method: 'POST', // HTTPメソッド
    //             headers: {
    //                 'Content-Type': 'application/json', // コンテントタイプをJSONに指定
    //             },
    //             body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
    //         });
    //     };
    //     try {
    //         const data = {  };
    //         sendRequest(`${process.env.REACT_APP_API_USER_OPENAIAPI}/test`);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // }

    const voiceSpeech = async(item) => {
        voiceData.voieModel='';
        voiceData.voiceTestText='';
        const message = messages.filter(message => message.id === item);
        const messageVoiceText = message[0].text;
        const messageAssistantId = message[0].user;
        if(messageVoiceText.length > 0) {
            // await setVoiceText(messageVoiceText);
            voiceData.voiceText = messageVoiceText
            if(messageAssistantId){
                getAssitantAndSpeech(messageAssistantId);
            }else{
                // await setVoiceModel('alloy');
                voiceData.voiceModel= 'alloy';
                getSpeech();
            }
        }

    }
    const onVoiceLoad = () =>{
        if (voiceRef.current) {
            voiceRef.current.play();
        }
    }

    const btPlus = () => {
        // fileUploadFunction('file');

        setFilePondAF([
            '.c','.cs','.cpp','.java','.py','.rb','.py',
            '.tex','.txt','.css','.js','.ts',
            '.xml','.csv',
            '.doc','.docx','.pptx','.xlsx',
            '.html','.pdf','.md','.json','.sh','.tar','.zip','.php',
            '.flac','.mp3','.mp4','.mpeg','.mpga','.m4a','.ogg','.wav','.webm'
        ]);
        const pond = pondRef.current;
        pond.componentDidUpdate();
        // pond.removeFiles();

        requestAnimationFrame(() => {
            pond.browse();
        });
    }

    const btImage = () => {
        // fileUploadFunction('image');

        setFilePondAF(['image/png','image/jpg','image/jpeg','image/gif']);
        const pond = pondRef.current;
        pond.componentDidUpdate();
        // pond.removeFiles();

        requestAnimationFrame(() => {
            pond.browse();
        });
    }
    // const btTest = () => {
    //     sendTest();
    // }
    const btMessageVoice = () => {
        setMessageVoice(!messageVoice);
    }

    const btVoice = () => {
        navigator.permissions.query({ name: 'microphone' }).then((permission) => {
            if (permission.state === 'denied') {
              // マイクへのアクセスが許可されていない
              // ユーザーにマイクへのアクセス許可を再確認するダイアログを表示
              setMediaPermission(true);
            } else {
                const options = {};
                if (MediaRecorder.isTypeSupported('audio/webm')) {
                    setAudioType({mimeType:'audio/webm',extention:'.webm'});
                } else if (MediaRecorder.isTypeSupported('audio/mpeg')) {
                    setAudioType({mimeType:'audio/mpeg',extention:'.mp3'});
                } else if (MediaRecorder.isTypeSupported('audio/wav')) {
                    setAudioType({mimeType:'audio/wav',extention:'.wav'});
                } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                    setAudioType({mimeType:'audio/mp4',extention:'.mp4'});
                } else if (MediaRecorder.isTypeSupported('audio/flac')) {
                    setAudioType({mimeType:'audio/flac',extention:'.flac'});
                } else if (MediaRecorder.isTypeSupported('audio/mpeg')) {
                    setAudioType({mimeType:'audio/mpeg',extention:'.mpeg'});
                } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
                    setAudioType({mimeType:'audio/ogg',extention:'.ogg'});
                }
              // マイクへのアクセスを取得
                setShowVoice(true);
                startRecording();
            }
          });
    };
    const mediaPermissionAlertAccept =() =>{
        setMediaPermission(false);
    }
    const onStopVoice =() =>{
        setShowVoice(false);
        stopRecording();
        // console.log(status);
        voiceFileSend();
    }

    const voiceFileSend = async() =>{
        const blob = await fetch(mediaBlobUrl).then(response => response.blob());
        if(blob.type.startsWith('audio/')) {
            const file = new File([blob], `voice${audioType.extention}`, { type: audioType.mimeType });
            sendMessageAndVoice(file);
        }
    }

    useEffect(()=>{
        voiceFileSend();
        clearBlobUrl();
    },[mediaBlobUrl])

    const cahatTemplate =(message) => {
        const timestamp = message.created_at;
        const date = new Date(timestamp * 1000); // タイムスタンプはミリ秒単位なので、1000 を掛けて秒に変換
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };
        const dateTimeString = date.toLocaleDateString('ja-JP', options);
        const minutesAgo = Math.floor((Date.now() - date.getTime()) / (1000 * 60));
        const timeContents = () =>{
            if(minutesAgo>60){
                return `${dateTimeString}`;
            }else{
                return `${minutesAgo}分前`;
            }
        }
        const timeDispalyText = timeContents();

        const aiAvaterContent = () => {
            return (
                <div className="flex align-items-left" style={{height:'15px',padding:'0px', display:'flex', flexDirection:'row'}}>
                    <Avatar image={selectedThread.selectedThread.avatar} shape="circle" style={{marginTop:'-8px'}} />
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span className="font-bold" style={{padding:'0px, 5px',marginTop:'-5px'}}>{message.sender}</span>
                        <div style={{fontSize:'0.7em'}}>{timeDispalyText}</div>
                    </div>
                </div>
            )
        }
        const selfAvaterContent = () => {
            return (
                <div className="flex align-items-right gap-2 px-2" style={{height:'15px',padding:'0px', display:'flex', flexDirection:'row'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span className="font-bold" style={{padding:'0px, 5px',marginTop:'-5px'}}>{message.sender}</span>
                        <div style={{fontSize:'0.7em'}}>{timeDispalyText}</div>
                    </div>
                    <Avatar icon="pi pi-user" shape="circle" style={{marginTop:'-8px'}}  />
                </div>
            )
        }
        
       if(message.talker === 'ai'){
            return aiAvaterContent();
        }else{
            return selfAvaterContent();
        }
    };
    function splitText(text) {
        const regex = /```(.*?)```/gs;
        const matches = [...text.matchAll(regex)];
        
        const arr = [];
        let lastIndex = 0;

        if (!matches.length) {
          // コードブロックがない場合
          arr.push({text:[text],lang:'text'});
          return arr;
        }
        
        
        matches.forEach(match => {
          // 通常文
          arr.push({text:text.substring(lastIndex, match.index),lang:'text'});
          // コードブロック（マッチの全体を追加）
          arr.push({text:match[0],lang:'script'});
          lastIndex = match.index + match[0].length;
        });
        
        // 最後の通常文
        arr.push({text:text.substring(lastIndex),lang:'text'});
        
        return arr;
    }

    function extractLanguageAndCode(consoleText) {
        // コードブロックとその言語を抽出する正規表現
        const regex = /```(\w+)\n([\s\S]*?)```/;
      
        const matches = consoleText.match(regex);
      
        if (!matches) {
        //   console.log("No code block found.");
          return null;
        }
      
        // matches[1] には言語が、matches[2] にはコードブロックの内容が格納されています
        const lang = matches[1];
        const text = matches[2];
      
        return { lang, text };
    }
    
    const onCopy = (text) => {
        // const text = 'ここにコピーしたいテキストです。';
        // copyRef.copyToClipboard(text);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      };
    const codeIP = (codeArray,key) =>{
        // console.log(codeArray);
        const lineCount = codeArray.text.split('\n').length;
        const fontSize = 14;
        const heightSize = lineCount * (fontSize+4);
        let language = codeArray.lang;
        switch(language){
            case 'javascript': language='javascript';
            break;
            case 'cpp': language='c_cpp';
            break;
            case 'python': language='python';
            break;
            case 'dart': language='dart';
            break;
            case 'dockerfile': language='dockerfile';
            break;
            case 'html': language='html';
            break;
            case 'java': language='java';
            break;
            case 'json': language='json';
            break;
            case 'jsp': language='jsp';
            break;
            case 'jsx': language='jsx';
            break;
            case 'kotlin': language='kotlin';
            break;
            case 'makefile': language='makefile';
            break;
            case 'mysql': language='mysql';
            break;
            case 'nginx': language='nginx';
            break;
            case 'objectivec': language='objectivec';
            break;
            case 'pascal': language='pascal';
            break;
            case 'perl': language='perl';
            break;
            case 'pgsql': language='pgsql';
            break;
            case 'php_laravel_blade': language='php_laravel_blade';
            break;
            case 'laravel': language='php_laravel_blade';
            break;
            case 'php': language='php';
            break;
            case 'sh': language='sh';
            break;
            case 'sql': language='sql';
            break;
            case 'svg': language='svg';
            break;
            case 'swift': language='swift';
            break;
            case 'typescript': language='typescript';
            break;
            case 'vbscript': language='vbscript';
            break;
            case 'vue': language='vue';
            break;
            case 'xml': language='xml';
            break;
            default: language='javascript';
            break;
        }
        // console.log(codeArray.lang)
        return (
            <div style={{backgroundColor:'black', padding:'0px 20px 20px 20px', borderRadius:'10px'}}>
                <div style={{color:'var(--primary-200)', padding:'20px 0px', display:'flex', justifyContent:'space-between'}}>
                    <div>
                        {t('lang')} : {codeArray.lang}
                    </div>
                    <CopyToClipboard text={codeArray.text} ref={copyRef} >
                    <Button 
                        icon="pi pi-copy" 
                        text aria-label="Desktop" 
                        type='button' 
                        tooltip={t('copy')}
                        style={{ color:'var(--primary-200)',top:'-10px'}}
                        onClick={onCopy(codeArray.text)}/>
                    </CopyToClipboard>
                </div>
                <AceEditor
                    id={key}
                    mode={language}
                    theme="terminal"
                    fontSize={fontSize}
                    showPrintMargin={false}
                    showGutter={false}
                    highlightActiveLine={false}
                    readOnly={true}
                    value={codeArray.text}
                    height = {heightSize}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        useWorker: false ,
                    }}
                    style={{
                        width: '100%',
                    }}
                />
            </div>
        )
    }

    const chatTextContent = (arr,key,indexCont) => {
        const filteredLanguages = arr.map((item, index) => {
            if (item.lang === 'text') {
                if (typeof item.text !== 'string') item.text = item.text[0];
                const newText = item.text.replace(/\\/g, '');
                // 正規表現を使ってメッセージから必要な部分を抜き出す
                const regex = /\[([^\]]+)\]\(sandbox:([^)]+)\)/;
                const matches = newText.match(regex);

                if (matches){
                    const label = matches[1]; // [ ] 内の文字列
                    const url = matches[2]; // sandbox: の後の文字列
                   if(label.length > 1 && url.length > 3) {
                        const before = newText.substring(0, matches.index);
                        const after = newText.substring(matches.index + matches[0].length);
                        return (
                            <div>
                            {before}
                                <Image 
                                    src={url}
                                    alt={label}
                                    width='40%'
                                    preview
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                />
                            {after}
                            </div>
                        );
                    }else{
                        return newText;
                    }
                }else{
                    return newText;
                }
            }else{
              const testLang = extractLanguageAndCode(item.text);
              return codeIP(testLang, `${indexCont + index + key}`);
            }
        });
        return filteredLanguages;
    }


    const inputHeight = () => {
        if(document.querySelector('.chat-input-panel')){
            const panelHeight = document.querySelector('.chat-input-panel').offsetHeight;
            const panelButtonHeight = document.querySelector('.input-button-area').offsetHeight;
            const inputAreaHeight = panelHeight-panelButtonHeight;
            setInputAreaHeightSize(inputAreaHeight);
            if(panelHeight <= 0){
                setTimeout(() => {
                    inputHeight();
                },500);
            }
        }
    }
    const deleteUploadFile = (item) =>{
        const pond = pondRef.current;
        const gFiles = pond.getFiles()
        for(let i = 0; i< gFiles.length; i++){
            if(gFiles[i].file === item.FileSystemFileHandle){
                pond.removeFile(i,true);
            }
        }
        // console.log(item.FileSystemFileHandle)
        // console.log(filePondSelectFiles)
        // const newUploadFile = uploadFiles.filter((file) => file !== item);
        const newUploadFile = filePondSelectFiles.filter((file) => file.file !== item.FileSystemFileHandle);
        setFilePondSelectFiles(newUploadFile);
        // const newUploadFile = uploadFiles;
        // setUploadFiles(newUploadFile);
    }

    const handleSubmit = () =>{
        const formData = new FormData();
        formData.append('file', uploadFiles);
    
        axios.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    }
    const itemTemplate = (item) => {
        const limit = 10;
        let shortItemName = item.FileSystemFileHandle.name;
        // console.log(shortItemName);
        if(item.FileSystemFileHandle.name.length > limit) shortItemName = item.FileSystemFileHandle.name.substring(0,limit) + '...';
        let fileType='pi pi-file';
        if(item.type === 'image')fileType='pi pi-image';
        return (
            <div 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign:'left'
                }}>
                <i 
                    className={fileType}
                    style={{
                        padding:'5px 0px'
                    }}
                 />
                <div 
                    style={{
                        width: '100%',
                        textAlign:'left',
                        padding:'0px 5px 0px 10px'
                    }}
                >
                    {shortItemName}
                </div>
                <Button
                    type='button'
                    onClick={()=>deleteUploadFile(item)}
                    icon='pi pi-trash'
                    rounded text
                    severity="danger"
                    style={{
                        width:'16px',
                        height:'16px'
                    }}
                    pt={{
                        icon:{
                            style:{
                                color:'var(--pink-700)',
                                fontSize:'10px'
                            }
                        },
                        root: {
                            style:{
                                  boxShadow: 'none',
                            }
                        }
                    }}
                    />
            </div>
        )
    }
    const uploadFileContents = () => {
        if(uploadFiles.length > 0) {
            const heightSize= inputAreaHeightSize;
            // const heightSize= 25;
           return (
                <div className="uploadFile">
                    <DataScroller 
                        value={uploadFiles} 
                        itemTemplate={itemTemplate} 
                        rows={5}
                        inline 
                        scrollHeight={heightSize}
                        pt={{
                            root:{
                                style:{
                                    width:'220px'
                                }
                            }
                        }}
                        />
                </div>
           )
        }
    }

    const getFileInfo = async(file_id) => {
        const id = file_id
        const thread_id = selectedThread.selectedThread.thread_id;
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const responceData = await response.json();
                await setFiles(files =>[...files,{file_id:file_id,name:responceData[0].filename}]);
            }
        };
        try {
            const data = { id, thread_id };
            const response = await sendRequest(`${process.env.REACT_APP_API_FILES}/info`,data);
            return response;
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const selectMessageCheck = (item) => {
        if (selectedMessages.includes(item)) {
            return true;
        }else{
            return false;
        }
    }
    const bookmarkToSelectMessage = (item) => {
        
    }
    const createVoiceMessage = (item) => {
        // setMessages(messages.filter((message) => message.id !== item));
        // setSelectedMessages(selectedMessages.filter((id) => id !== item));
        // deleteMessage(item);
    }
    const deleteSelectMessage = (item) => {
        setMessages(messages.filter((message) => message.id !== item));
        setSelectedMessages(selectedMessages.filter((id) => id !== item));
        deleteMessage(item);
    }
    const messageClick = (itemId) =>{
        // console.log(itemId);
        const selectElement = document.querySelector(`#${itemId}`)
        // itemId が選択済みメッセージに存在しない場合は追加
        if (!selectedMessages.includes(itemId)) {
            setSelectedMessages([...selectedMessages, itemId]);
            // メッセージ要素を表示
            selectElement.style.visibility = 'visible';
        }else{
            setSelectedMessages(selectedMessages.filter((id) => id !== itemId));
            selectElement.style.visibility = 'hidden';
        }
    }
    const messageVisibleDataCount = () =>{
        const messageCount = messages.filter((message) => message.metadata?.deleted !== 'true');
        return messageCount.length;
    }
    const chatContentTemplate = ( index, key ) =>{
        const arr = splitText(messages[index].text);
        const id = messages[index].id;
        const file_ids = messages[index].file_ids;
        const metadata = messages[index].metadata;
        const file_idsContent = () =>{
            if (file_ids.length > 0) {
                const fileIdContent =  file_ids.map(file_id =>{
                    const file = files.find((file) => file.file_id === file_id);
                    if(file){
                        const extension = file.name.split('.').pop().toLowerCase();
                        const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
                        let icon = 'pi-file';
                        if(extension === 'pdf')icon ='pi-file-pdf';
                        if(extension === 'doc')icon ='pi-file-word';
                        if(extension === 'docx')icon ='pi-file-word';
                        if(extension === 'xlsx')icon ='pi-file-excel';
                        if(imageExtensions.includes(extension)){
                            //画像
                            return (
                                <div
                                style={{
                                    textAlign: 'center',
                                    padding:'10px 10px',
                                    margin: '10px 10px',
                                    backgroundColor:'var(--primary-900)',
                                    borderRadius: '6px'
                                }}>
                                    <Image 
                                        src={`/uploads/files/${file.name}`}
                                        alt="image file"
                                        width="250"
                                        preview
                                    />
                                </div>
                            )
                        }else{
                            //画像以外のファイル
                            return (
                                <div 
                                    style={{
                                        textAlign: 'center',
                                        padding:'10px 10px',
                                        margin: '10px 10px',
                                        backgroundColor:'var(--primary-300)',
                                        borderRadius: '6px'
                                    }}>
                                    <a 
                                        href={`/uploads/files/${file.name}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className={`pi ${icon}`} style={{ fontSize: '1.2rem' }}>
                                        {extension}
                                        </i>
                                    </a>
                                </div>
                            )
                        }
                    }else{
                        return null
                    }
                })
                return fileIdContent
            }else{
                return
            }
        }
        if(metadata?.deleted === 'true'){
            return
        }else{
            return (
                <li key={id}
                    style={{
                        listStyle:'none'
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection: 'row',
                            padding:'0px 10px',
                            justifyContent: messages[index].talker==='ai'?'flex-start':'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            id={id}
                            style={{ 
                                display:'flex',
                                flexDirection:'column',
                                gap:'20px',
                                width:'20px',
                                visibility:selectMessageCheck(id) ? 'visible' : 'hidden',
                            }}
                        >
                            <Button
                                type='button'
                                icon='pi pi-bookmark'
                                onClick={()=>bookmarkToSelectMessage(id)}
                                style={{
                                    width:'20px',
                                    height:'20px'
                                }}
                                pt={{
                                    root: {
                                        style:{
                                            boxShadow: 'none',
                                            backgroundColor:'transparent',
                                            border:'none'
                                        }
                                    }
                                }}
                            />
                            <Button
                                type='button'
                                icon='pi pi-volume-up'
                                onClick={()=>voiceSpeech(id)}
                                style={{
                                    width:'20px',
                                    height:'20px'
                                }}
                                pt={{
                                    root: {
                                        style:{
                                            boxShadow: 'none',
                                            backgroundColor:'transparent',
                                            border:'none'
                                        }
                                    }
                                }}
                            />
                            <Button
                                type='button'
                                icon='pi pi-trash'
                                onClick={()=>deleteSelectMessage(id)}
                                style={{
                                    width:'20px',
                                    height:'20px'
                                }}
                                pt={{
                                    root: {
                                        style:{
                                            boxShadow: 'none',
                                            backgroundColor:'transparent',
                                            border:'none'
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div 
                            onClick={() => messageClick(id)}
                            style={{
                                display:'flex',
                                flexDirection: messages[index].talker==='ai'?'row':'row-reverse',
                                padding:'0px 20px'
                            }}>        
                                <Fieldset
                                    legend ={cahatTemplate(messages[index])}
                                    pt={{
                                        legend: { 
                                            style:{
                                                minWidth:'180px',
                                                padding:'10px',
                                                backgroundColor:'var(--highlight-bg)',
                                                color:'var(--text-color-secondary)', 
                                                textAlign:messages[index].talker!=='ai'?'right':'',
                                                display:'flex',
                                                flexDirection:messages[index].talker==='ai'?'row':'row-reverse'
                                            } 
                                            },
                                        content: { style:{padding:'10px', color:'var(--primary-color-text)'} }
                                    }}
                                    className={`message ${messages[index].talker}`}
                                    style={{ 
                                        backgroundColor: messages[index].talker==='ai'?'var(--primary-700)':'var(--primary-800)',
                                        textAlign: messages[index].talker==='ai'?'left':'right'
                                    }}
                                >
                                <div
                                    style={{
                                        textAlign:'left',
                                    }}
                                >{chatTextContent( arr, key, index )}</div>
                                {file_idsContent()}
                                
                            </Fieldset>
                        </div>
                    </div>
                </li>
            )
        }
        
    }

    const splitterOnResize=() => {
        inputHeight();
    }
    const permissionAlertHeader = () => {
        return(
            <div style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex-start',
                }}
            >
            <Avatar icon="pi pi-exclamation-triangle" size="large" shape="circle" />
            <div style={{padding:'10px'}}>{t('alert')}</div>
            </div>
        )
    }

    const permissionAlertFooter = () => {
        return(
            <div 
                style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center'
                }}
            >
                <Button 
                    label='OK'
                    onClick={()=>{setMediaPermission(false)}}
                />
            </div>
        )

    }

    const backButtonContent =() =>{
        if(window.innerWidth < 768){
            return(
                <Button
                    icon={'pi pi-angle-double-left'}
                    onClick={() => move(true)}
                    type='button'
                    className='p-button-secondary'
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '20%',
                        backgroundColor: 'var(--primary-800)',
                        color: 'var(--primary-color-text)',
                        borderColor:'var(--primary-color-text)',
                    }}
                />
            )
        }else{
            return '';
        }
    }


    return (
        <div className="layout-wrapper" style={{ height: '100%', width: '100%' }} ref={elementRef}>
            <div className='Chatwindow message-container' style={{ height: '100%', width: '100%' }}>
                {selectedThread.selectedThread ? (
                    <div style={{
                        width: '100%',
                        height: '100%',
                    }}>
                        {/* タイトルセクション */}
                        <Toolbar start={backButtonContent()} start={backButtonContent()} center={titleContent(selectedThread.selectedThread.name)} end={endContent}
                            style={{ 
                                width: 'auto', height: '60px',
                                paddingTop:'10px',
                                backgroundColor: 'var(--primary-color)', 
                                color:'var(--primary-color-text)',
                                display:'flex',
                                justifyContent: 'flex-between',
                             }}
                        />
                        <Splitter 
                            style={{ height: leftWindowHeight }} 
                            onResizeEnd={inputHeight}
                            layout="vertical" 
                            ref={topPanelRef}
                            gutterSize={2} 
                            /* eslint-disable */
                            onResizeEnd={splitterOnResize}
                            pt={{
                              gutterhandler:{
                                style:{
                                  backgroundColor:'var(--gray-600)',
                                  width:'25px'
                                }
                              }
                            }}
                            >
                            <SplitterPanel 
                                className="chat-message-panel" 
                                size="95" 
                                style={{ overflowY: 'hidden' }}>
                                <Toast ref={toastOnChat} />
                                <div className="chat-window" 
                                    style={{ overflow: 'auto', width:'100%',height:'100%', padding:'0px',
                                    backgroundColor:'var(--primary-600)'}}>
                                    <ReactList
                                        key='chat-window'
                                        ref={chatScrollPanelRef}
                                        itemRenderer={chatContentTemplate}
                                        length={messageVisibleDataCount()}
                                        type='variable'
                                    />
                                </div>
                            </SplitterPanel>
                            <SplitterPanel 
                                className="chat-input-panel flex align-items-center justify-content-center" 
                                size="5"
                                style={{minHeight:'150px',maxHeight:'300px'}}
                                >
                                {/* 入力セクション（可変） */}
                                <div 
                                    className="input-area" 
                                    style={{ width: '100%' , backgroundColor: 'var(--primary-800)'}} 

                                    ref={botomPanelRef}>
                                    {/* ボタン */}
                                    <div className="input-button-area" 
                                        ref={buttonAreaRef} 
                                        style={{ 
                                            backgroundColor: 'var(--primary-color)',
                                            border:'0.5px solid var(--surface-50)',
                                            borderRadius:'6px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                            }}>
                                        {/* 左寄せのボタン */}
                                        <div className="left-buttons" style={{display:'flex',flexDirection:'row'}}>
                                            <div>
                                                <FilePond 
                                                    ref={pondRef}
                                                    onupdatefiles={setFilePondSelectFiles}
                                                    acceptedFileTypes={filePondAF}
                                                    maxFiles={uploadFilesMax}
                                                    allowMultiple={allowMultiple}
                                                    maxFileSize={maxFileSize}
                                                />
                                            </div>
                                                                                    
                                            {/* <Button 
                                                icon="pi pi-prime" 
                                                text aria-label="Prime" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btPrime}
                                                pt={{
                                                    root: {
                                                        style:{
                                                            boxShadow: 'none',
                                                        }
                                                    }
                                                }}
                                                /> */}
                                            <Button 
                                                icon="pi pi-plus" 
                                                text aria-label="Plus" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btPlus}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                                }}/>
                                            <Button 
                                                icon="pi pi-image"
                                                text aria-label="Image" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btImage}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                                }}/>
                                            {/* <Button 
                                                icon="pi pi-camera" 
                                                text aria-label="Camera" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btCamera}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                                }}
                                                /> */}
                                            {/* <Button 
                                                icon="pi pi-desktop" 
                                                text aria-label="Desktop" 
                                                type='button' 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btDesktop}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                                }}/> */}
                                        </div>
                                        {/* 右寄せのボタン */}
                                        <div className="right-buttons">
                                            {/* <Button 
                                                icon='pi pi-flag'
                                                text aria-label="Microphone" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btTest}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                            }}/> */}
                                            <Button 
                                                icon={messageVoice? 'pi pi-volume-up':'pi pi-volume-off'} 
                                                text aria-label="Microphone" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btMessageVoice}
                                                pt={{
                                                    root: {
                                                        style:{
                                                              boxShadow: 'none',
                                                        }
                                                    }
                                            }}/>
                                            <Button 
                                                icon="pi pi-microphone" 
                                                text aria-label="Microphone" 
                                                style={{ color:'var(--primary-color-text)'}}
                                                onClick={btVoice}
                                                pt={{
                                                    root: {
                                                        style:{
                                                                boxShadow: 'none',
                                                        }
                                                    }
                                            }}/>
                                            <audio ref={voiceRef}
                                                src={speechFile}
                                                onLoadedData={onVoiceLoad}
                                            />
                                        </div>
                                    </div>
                                    {/* メッセージ入力エリア */}
                                    {/* <form onSubmit={sendMessage} className="input-form"> */}
                                    <form className="input-form">
                                        <div style={{position:'relative',width:'100%'}}>
                                            <div style={{ 
                                                position:'absolute', 
                                                backgroundColor: 'var(--primary-800)',
                                                display:'flex', 
                                                // flexDirection:'row', 
                                                alignSelf: 'stretch',
                                                width: '100%',
                                                height:'100%' }}>
                                                <InputTextarea
                                                    ref={chatInputRef}
                                                    value={input}
                                                    autoResize={false}
                                                    onChange={(e) => setInput(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                    rows={inputareaHeight} // 必要に応じて行数を調整する
                                                    placeholder={t('enterYourMessage')}
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',height: (inputAreaHeightSize),
                                                        border: 'none',
                                                        backgroundColor: 'var(--primary-800)',
                                                        color:'var(--primary-200)'
                                                    }}
                                                    className="input-textarea"
                                                />
                                                {uploadFileContents()}
                                            </div>
                                            <Dialog header={permissionAlertHeader} footer={permissionAlertFooter} visible={mediaPermission} style={{ width: '50vw' }} onHide={() => setMediaPermission(false)}>
                                                <p className="m-0">
                                                    {t('mediaPermissionDeniedError')}
                                                </p>
                                            </Dialog>
                                            <div
                                                ref={chatVoiceRef}
                                                style={{
                                                    popsition: 'absolute',
                                                    opacity: '0.5',
                                                    width: '100%', height: '100%',
                                                    top:'20px',
                                                    textAlign: 'center',
                                                    backgroundColor: 'var(--primary-200)',
                                                    display: showVoice ? 'block' : 'none',
                                                    visibility: showVoice ? 'visible' : 'hidden'
                                                }} 
                                            >
                                            <div
                                                style={{
                                                    marginTop: '0px',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                {t('recTime')}
                                            </div>
                                                <Button
                                                    icon="pi pi-pause"
                                                    type='button' 
                                                    rounded
                                                    style={{ color:'var(--primary-color-text)'}}
                                                    onClick={onStopVoice}
                                                    pt={{
                                                        root: {
                                                            style:{
                                                                  boxShadow: 'none',
                                                                  marginTop:'10px'
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <ProgressSpinner
                                                className="spinner"
                                                ref={chatInputProgressRef}
                                                style={{
                                                    popsition: 'absolute',
                                                    opacity: '0.5',
                                                    width: '100%', 
                                                    height: (inputAreaHeightSize),
                                                    textAlign: 'center',
                                                    // display:'block',
                                                    display: showSpinner ? 'block' : 'none',
                                                    visibility: showSpinner ? 'visible' : 'hidden'
                                                }} 
                                                strokeWidth="8" 
                                                animationDuration=".5s" 
                                            />
                                        </div>
                                    </form>
                                </div>
                            </SplitterPanel>
                        </Splitter>
                    </div>
                ) : (
                    <div style={{
                        width: '100%',
                        height: '100%',
                    }}>
                        {/* タイトルセクション */}
                        <Toolbar start={backButtonContent()} center={titleContent(t('pleaseSelectAChat'))}
                            style={{ width: 'auto', height: '60px', 
                            backgroundColor: 'var(--primary-color)', color:'var(--primary-color-text)' }}
                        />
                        <Splitter style={{ height:'100%' }} layout="vertical">
                            <ScrollPanel className="chatScroll" 
                                        style={{ width: '100%', height: '100%', padding: '10px', 
                                            overflowY: 'auto', overflowX: 'hidden',
                                            backgroundColor: 'var(--primary-900)' }}>
                                    <div className="chat-window" >
                                    </div>
                            </ScrollPanel>
                        </Splitter>
                    </div>
                )}
            </div>
        </div>
    )
}