import React, { useState, useEffect, useContext } from 'react';
import { StateAssistantChange } from '../App';
import { useTranslation } from 'react-i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { PanelMenu } from 'primereact/panelmenu';
import { Avatar } from 'primereact/avatar';

const AssistantEditorList = ({onChange}) => {
    const { t } = useTranslation();
    const { assistantChange, setAssistantChange } = useContext(StateAssistantChange);
    const [expandedKeys, setExpandedKeys] = useState({'0':false,'1':false,'2':true,'3':false});
    const [ assistantsList, setAssistantsList ] = useState([]);

    const searchContent = (
        <div className="p-input-icon-left" 
        style={{
            width:'100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin:'5px',
            backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
            }}>
            <i className="pi pi-search" style={{color:'var(--primary-color-text)'}}/>
            <InputText placeholder={t('search')} 
                style={{width:'100%',
                    backgroundColor:'var(--primary-400)',color:'var(--primary-color-text)'
                }}/>
        </div>
    )

    const assistantSearchBar = () =>{
        return(
            <div className="card p-fluid" 
            style={{ 
                width: 'auto', 
                height:'60px', 
                margin:'0px', 
                backgroundColor: 'var(--primary-500)',
                color:'var(--primary-color-text)',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                {searchContent}
            </div>
        )
    };


    const itemRenderer = (item) => (
        <div className='p-menuitem-content' style={{ backgroundColor:'var(--primary-500)'}}>
            <div className="flex align-items-center p-menuitem-link" onClick={()=>changeAssistant(item.assistant_id)} 
            style={{color:'var(--primary-color-text)'}}>
                {item.avatar ? (
                    <Avatar image={item.avatar} size="normal" shape="circle" style={{minWidth:'45px',minHeight:'45px'}} />
                ):(
                    <Avatar icon="pi pi-user" size="normal" shape="circle" style={{minWidth:'45px',minHeight:'45px'}} />
                )}
                <div style={{marginLeft:'10px'}}>{item.name}</div>
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </div>
        </div>
    );

    // 仮ユーザーデーター
    const items = [
        {
            key: '0',
            label: t('assistantEditorManual'),
            icon: 'pi pi-list',
            command: () => {
                changeAssistant('top')
            }
        },
        {
            key: '1',
            label: t('makeAnAssistant'),
            icon: 'pi pi-user-plus',
            command: () => {
                changeAssistant('new')
            }
        },
        {
            key: '2',
            label: t('createdAssistants'),
            items: assistantsList.map((assistant, index) => ({
                key: `2_${index+1}`,
                name: assistant.name,
                assistant_id: assistant.assistant_id,
                avatar: `uploads/${assistant.avatar}`,
                template: itemRenderer,
            })),
        },
        {
            key: '3',
            label: t('freindsSearch'),
            icon: 'pi pi-users',
            command: () => {
                changeAssistant('friend')
            }
        },
    ]

    const changeAssistant = (assistant) =>{
            onChange(assistant)
    }

    const menuItemList = () => {
        return(
            <div style={{ height: '100%' }}>
                <ScrollPanel style={{ width: '100%', height: '100%', overflowY:'auto',paddingBottom:'60px' }}>
                    <PanelMenu 
                        pt={{
                            menuContent:{
                                style:{
                                    backgroundColor:'var(--primary-500)',color:'var(--primary-color-text)'
                                }},
                            headerAction: () => ({
                                style:{
                                    backgroundColor:'var(--primary-500)',
                                    color:'var(--primary-color-text)'
                                }
                                })
                        }}
                        model={items} className="w-full md:w-15rem"
                        expandedKeys={expandedKeys} 
                        onExpandedKeysChange={setExpandedKeys}
                    style={{width:'100%',padding:'5px'}}/>
                </ScrollPanel>
            </div>
        )
    }
    const getAssitantList = async() =>{
        const token = localStorage.getItem('token');
        const searchflug = 'self';
        const data = { token,searchflug };
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                //const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                // console.log('Assistant created successfully');
                const list = await response.json();
                setAssistantsList(list);
                // console.log(list);
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_ASSISTANTS}/list`);
        } catch (error) {
            console.error('Error:', error);
        }
    }
   
    sessionStorage.setItem('menuRefulesh',false);
    localStorage.setItem('menuRefuresh',false);
    const handleStorageChange = (event) => {
        console.log('in handle')
        if (event.storageArea === localStorage) {
                // ローカルストレージの値が変更された時の処理
                console.log('change local');
        } else if (event.storageArea === sessionStorage) {
                console.log('sessionChange')
                // セッションストレージの値が変更された時の処理
            if(sessionStorage.getItem('menuRefulesh')){
                console.log('IN');
                sessionStorage.setItem('menuRefulesh',false);
            }
        }
    }

    useEffect(() => {
        if(assistantChange)getAssitantList();
        setAssistantChange(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assistantChange]);

    useEffect(()=>{
        getAssitantList();
        window.addEventListener('storage', handleStorageChange);
      
        // return () => window.removeEventListener('storage', handleStorageChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])// このuseEffectはコンポーネントのマウント時にのみ実行されます

    return (
        <div
        style={{
            width: '100%', 
            height:'100%', 
        }}>
            {assistantSearchBar()}
            {menuItemList()}
        </div>
    )
}
export default AssistantEditorList;