const SetSessionStrage = async () =>{
    const token = localStorage.getItem('token');
    const getRole = () => {
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                // console.log(body.role_id)
                sessionStorage.setItem("role", body.role_id);
            }
        };
        try {
            const data = { token }
            sendRequest(process.env.REACT_APP_API_USER_ROLES,data);
        } catch (error) {
            console.error('Error:', error);
            localStorage.removeItem('token');
            window.location.reload();
        }
    };
    const getSystem = async() => {
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                // console.log(body.system[0])
                sessionStorage.setItem("language", body.system[0].language);
                sessionStorage.setItem("darkmode", body.system[0].darkmode);
                sessionStorage.setItem("theme", body.system[0].thene);
            }
        };
        try {
            const data = { token }
            sendRequest(process.env.REACT_APP_API_SYSTEM,data);
        } catch (error) {
            console.error('Error:', error);
            window.location.reload();
        }
    };
    const getUsers = async() => {
        const sendRequest = async (url,data) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.status === 200) {
                const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                // console.log(body.system[0])
                sessionStorage.setItem("username", body.system[0].username);
                sessionStorage.setItem("email", body.system[0].email);
            }
        };
        try {
            const data = { token }
            sendRequest(`${process.env.REACT_APP_API_USERS}/user`,data);
        } catch (error) {
            console.error('Error:', error);
            window.location.reload();
        }
    };
    if(token){
        getRole();
        getSystem();
        getUsers();
    }

    return;

}
export default SetSessionStrage;